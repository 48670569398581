// TODO Extract to shared library

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { isInvalid } from '../utils/formikUtils';
import TranslatedInput from './TranslatedInput';

const MemoTranslatedInput = React.memo(TranslatedInput);

export default class ValidatedInputField extends Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
      value: PropTypes.any,
    }).isRequired,
    form: PropTypes.shape({
      errors: PropTypes.object.isRequired,
      touched: PropTypes.object.isRequired,
      submitCount: PropTypes.number.isRequired,
      values: PropTypes.object.isRequired,
    }).isRequired,
    autoComplete: PropTypes.string,
    validationMessageClassName: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    autoComplete: 'off',
    validationMessageClassName: null,
    type: 'dropdown',
  };

  shouldComponentUpdate(nextProps) {
    const {
      field: { name, value: fieldValue },
      form: { errors, submitCount, touched },
    } = this.props;
    return Object.entries(nextProps).some(([key, value]) => {
      if (key === 'form') {
        return (
          get(value.errors, name) !== get(errors, name) ||
          value.submitCount !== submitCount ||
          get(value.touched, name) !== get(touched, name)
        );
      }
      if (key === 'field') {
        return value !== fieldValue;
      }
      /* eslint-disable-next-line react/destructuring-assignment */
      return value !== this.props[key];
    });
  }

  render() {
    const {
      field: { name, onBlur, onChange, value },
      form: { errors, touched, submitCount },
      validationMessageClassName,
      type,
      ...rest
    } = this.props;

    const invalid = isInvalid(name, errors, touched, submitCount);
    return (
      <>
        <MemoTranslatedInput
          id={name}
          name={name}
          value={value !== undefined && value !== null ? value : ''}
          onBlur={onBlur}
          onChange={onChange}
          passwordToggle={type === 'password'}
          type={type}
          invalid={invalid ? get(errors, name) : false}
          {...rest}
        />
      </>
    );
  }
}
