import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { server } from 'assets/images/hardware';
import { SIDE_IMAGE_TYPE } from 'state/ui/constants';
import { mfaSideImage } from 'assets/images/pages';

const imageMap = {
  default: server,
  mfa: mfaSideImage,
};

const Image = styled.img`
  max-width: ${({ insideProgress }) => (insideProgress ? '200%' : '100%')};
  ${({ insideProgress }) => (insideProgress ? 'margin-top: 45%;' : '')}
  z-index: 1;
  padding: 0 20px;
`;

export default function DeviceImage({ hardwareInfo, insideProgress, image }) {
  return <Image src={imageMap[image]} alt={hardwareInfo.name} insideProgress={insideProgress} />;
}

DeviceImage.defaultProps = {
  insideProgress: false,
  image: 'default',
};

DeviceImage.propTypes = {
  hardwareInfo: PropTypes.object.isRequired,
  insideProgress: PropTypes.bool,
  image: PropTypes.oneOf(Object.values(SIDE_IMAGE_TYPE)),
};
