import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';

export const createPaginatedSelector = itemsSelector =>
  createSelector(
    itemsSelector,
    items =>
      memoizeOne(({ currentPageIndex, rowsPerPage }) => {
        const totalRows = items.length;
        const currentPage = currentPageIndex + 1;
        const offset = currentPageIndex * rowsPerPage;
        const allItems = items;
        const rows = items.slice(offset, offset + rowsPerPage);
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        return { allItems, currentPage, rows, totalPages, totalRows };
      }),
  );
