// TODO Extract to shared library

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Input from '@ubnt/ui-components/Input/Input';

const TranslatedInput = injectIntl(({ intl, label, labelValues, placeholder, autoComplete, ...rest }) => (
  <Input
    label={label && intl.formatMessage({ id: label }, labelValues)}
    placeholder={placeholder && intl.formatMessage({ id: placeholder })}
    autoComplete={autoComplete}
    variant="secondary"
    {...rest}
  />
));

TranslatedInput.propTypes = {
  placeholder: PropTypes.string,
};

TranslatedInput.defaultProps = {
  labelValues: {},
  placeholder: null,
};

export default TranslatedInput;
