import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import Text from '@ubnt/ui-components/Text/Text';
import StatusBar from '@ubnt/ui-components/StatusBar/StatusBar';

import StepContent from 'components/StepContent';
import StepHeader from 'components/StepHeader';

const VerticallyCentered = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  flex-direction: column;
  justify-content: center;
`;

const SetupProgress = styled(StatusBar)`
  width: 225px;
`;

export default function Progress({ messageId, percent }) {
  return (
    <VerticallyCentered>
      <StepContent>
        <StepHeader titleId={messageId} />
      </StepContent>
      <SetupProgress value={percent} size="large" animate />
      <Text color="tertiary" size="caption">
        <FormattedMessage id="SETUP_SETTING_UP_TIME_REMAINING" values={{ time: '1m' }} />
      </Text>
    </VerticallyCentered>
  );
}

Progress.defaultProps = {
  percent: null,
};

Progress.propTypes = {
  messageId: PropTypes.string.isRequired,
  percent: PropTypes.number,
};
