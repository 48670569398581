import { combineReducers } from 'redux';

import { reducers as locales } from './locales';
import { reducers as models } from './models';
import { reducers as setup } from './setup';
import { reducers as ui } from './ui';

const rootReducer = combineReducers({
  models,
  locales,
  ui,
  setup,
});

export default rootReducer;
