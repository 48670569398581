import { connect } from 'react-redux';

import { selectDeviceName, selectIsUnifiGo } from 'state/models/sysInfo';
import ReviewConfiguration from './ReviewConfiguration';

const mapStateToProps = state => ({
  deviceName: selectDeviceName(state),
  isUnifiGo: selectIsUnifiGo(state),
});

export default connect(mapStateToProps)(ReviewConfiguration);
