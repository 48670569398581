import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup } from '@ubnt/react-forms';

import StepContent from 'components/StepContent';
import StepHeader from 'components/StepHeader';
import { Field } from 'lib/formik/components';
import { validateFields } from 'utils/formik';

const CHECK_FIELDS = ['localAdminUsername', 'localAdminPassword', 'localAdminPasswordConfirm'];

export default function UnifiGoCredentials({ renderControls, validationSchema, values }) {
  const disabled = !validateFields(validationSchema, values, CHECK_FIELDS);

  return (
    <StepContent>
      <StepHeader
        titleId="SETUP_HEADER_TITLE_UNIFI_GO_CREDENTIALS"
        descriptionId="SETUP_HEADER_DESC_UNIFI_GO_CREDENTIALS"
      />
      <FormGroup>
        <Field name="localAdminUsername" label="SETUP_LABEL_LOCAL_ADMIN_USERNAME" />
        <Field name="localAdminPassword" label="SETUP_LABEL_LOCAL_ADMIN_PASSWORD" type="password" />
        <Field type="password" name="localAdminPasswordConfirm" label="SETUP_LABEL_LOCAL_ADMIN_PASSWORD_CONFIRM" />
      </FormGroup>
      {renderControls({ disabled })}
    </StepContent>
  );
}

UnifiGoCredentials.propTypes = {
  renderControls: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};
