import apiClient from 'utils/api/apiClient';

export const FETCH_UPDATE_PROGRESS = 'FETCH_UPDATE_PROGRESS';
export const fetchUpdateProgress = () => dispatch => {
  const promise = apiClient.get('/api/stat/updateprogress');

  dispatch({ type: FETCH_UPDATE_PROGRESS, promise });

  return promise;
};
