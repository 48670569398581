import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import rootReducer from 'state/rootReducer';

function configureStore() {
  const middlewares = [thunk, reduxPackMiddleware];
  let composeFn = compose;

  if (__DEV__) {
    const logger = createLogger({
      collapsed: true,
    });

    middlewares.push(logger);
    composeFn = composeWithDevTools;
  }

  return createStore(rootReducer, composeFn(applyMiddleware(...middlewares)));
}

export default configureStore();
