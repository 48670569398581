import get from 'lodash.get';
import { createSelector } from 'reselect';
import { createPaginatedSelector } from 'utils/pagination/selectors';
import { BACKUP_TYPE } from './constants';

export const selectBackups = state => get(state, 'models.backups.data');
export const selectBackupList = backupType =>
  createSelector(
    selectBackups,
    backups =>
      (backups || []).map(item => ({ ...item, id: item.filename })).filter(item => backupType.includes(item.type)),
  );

export const selectBackendStatus = state => get(state, 'models.backups.isBackendUp');
export const selectPaginatedCloudBackups = createPaginatedSelector(selectBackupList([BACKUP_TYPE.CLOUD]));
export const selectPaginatedLocalBackups = createPaginatedSelector(
  selectBackupList([BACKUP_TYPE.LOCAL, BACKUP_TYPE.SECONDARY]),
);
export const selectError = state => get(state, 'models.backups.error');
export const selectLoadingBackup = (state, key) => !!get(state, `models.backups.${key}`);
export const selectRestoringBackup = state => !!get(state, 'models.backups.restoringBackup');
export const selectUploadedBackupInfo = state => get(state, 'models.backups.restoreBackupInfo');
