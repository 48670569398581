import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { selectLanguage, selectTranslations } from 'state/locales/selectors';
import { DEFAULT_LANGUAGE } from 'state/locales/constants';
import { flattenMessages, getTranslationsKey } from 'state/locales/utils';

function IntlProviderContainer({ language, translations, ...rest }) {
  return (
    <IntlProvider
      key={getTranslationsKey(translations)}
      locale={language}
      defaultLocale={DEFAULT_LANGUAGE}
      messages={flattenMessages(translations, language)}
      {...rest}
    />
  );
}

IntlProviderContainer.propTypes = {
  language: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  language: selectLanguage(state),
  translations: selectTranslations(state),
});

export default connect(mapStateToProps)(IntlProviderContainer);
