import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StepContent from 'components/StepContent';
import StepHeader from 'components/StepHeader';
import ValidatedFieldStack from 'components/ValidatedFieldStack';
import { FormattedLink } from 'components/Link';
import { Field } from 'lib/formik/components';
import { validateFields } from 'utils/formik';

const CHECK_FIELDS = ['localAdminUsername', 'localAdminPassword', 'localAdminPasswordConfirm', 'localAdminEmail'];

export default class AdvancedAccess extends Component {
  componentDidMount = () => {
    /* If we are here, it means we won't use Ubiquiti login */
    const { setFieldTouched, setFieldValue } = this.props;
    setFieldValue('ubiquitiLoginEnabled', false);
    setFieldValue('cloudAccessEnabled', false);
    setFieldTouched('ubiquitiLoginEnabled', false);
    setFieldTouched('cloudAccessEnabled', false);
  };

  cleanUpFields = fields => {
    const { initialValues, setFieldTouched, setFieldValue } = this.props;
    fields.forEach(field => {
      setFieldValue(field, initialValues[field]);
      setFieldTouched(field, false);
    });
  };

  afterEnableCloudAccessChange = value =>
    !value &&
    this.cleanUpFields(['ubiquitiAccount', 'ubiquitiPassword', 'ubiquiti2faTokenRequired', 'ubiquiti2faToken']);

  afterEnableUbiquitiLoginChange = value =>
    value &&
    this.cleanUpFields(['localAdminUsername', 'localAdminPassword', 'localAdminPasswordConfirm', 'localAdminEmail']);

  render() {
    const { renderControls, validationSchema, values, setFlow } = this.props;
    const disabled = !validateFields(validationSchema, values, CHECK_FIELDS);

    return (
      <StepContent>
        <StepHeader
          titleId="SETUP_ADVANCED_SERVER_PASSWORD_TITLE"
          descriptionId="SETUP_ADVANCED_SERVER_PASSWORD_DESC"
          descriptionValues={{
            cta: (
              <FormattedLink messageId="SETUP_LEARN_MORE" href="https://help.ui.com/hc/en-us/articles/11444786290071" />
            ),
          }}
        />
        <ValidatedFieldStack>
          <Field name="localAdminUsername" label="SETUP_USERNAME" />
          <Field name="localAdminPassword" label="SETUP_PASSWORD" type="password" />
          {values.localAdminPassword && (
            <Field type="password" name="localAdminPasswordConfirm" label="SETUP_CONFIRM_PASSWORD" />
          )}
          <Field name="localAdminEmail" label="SETUP_EMAIL" type="email" />
        </ValidatedFieldStack>

        {renderControls({
          disabled,
          onPrevClicked: () => {
            const { setFieldValue, initialValues } = this.props;
            setFieldValue('ubiquitiLoginEnabled', initialValues.ubiquitiLoginEnabled);
            setFlow('defaultFlow', 'credentials');
          },
        })}
      </StepContent>
    );
  }
}

AdvancedAccess.propTypes = {
  initialValues: PropTypes.object.isRequired,
  renderControls: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setFlow: PropTypes.func.isRequired,
};
