// TODO Extract to shared library

import React from 'react';
import { injectIntl } from 'react-intl';
import Dropdown from '@ubnt/react-dropdown';

const TranslatedDropdown = injectIntl(({ intl, options, ...rest }) => (
  <Dropdown
    options={options.map(option => ({
      ...option,
      label: intl.formatMessage({ id: option.label }, option.values || {}),
    }))}
    {...rest}
  />
));

export default TranslatedDropdown;
