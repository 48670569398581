import React from 'react';
import styled from '@emotion/styled';

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div:last-child {
    margin-right: 24px;
  }
  line-height: 1.5;
`;

export default function LabelGroup(props) {
  return <StyledDiv className="mb-8" {...props} />;
}
