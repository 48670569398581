import apiClient from 'utils/api/apiClient';

export const CREATE_WLAN = 'CREATE_WLAN';
export const createWlan = ({ apGroupId, name, password, combineSsid }) => dispatch => {
  const promise = apiClient.post('/api/rest/wlanconf', {
    ap_group_ids: [apGroupId],
    enabled: true,
    name,
    name_combine_enabled: combineSsid,
    security: 'wpapsk',
    wlan_band: 'both',
    wpa_enc: 'ccmp',
    wpa_mode: 'wpa2',
    x_passphrase: password,
    setting_preference: 'auto',
  });

  dispatch({ type: CREATE_WLAN });

  return promise;
};
