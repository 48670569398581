import axios from 'axios';

const apiClient = axios.create({
  baseURL: '/',
  withCredentials: true,
  xsrfCookieName: 'csrf_token',
  xsrfHeaderName: 'X-Csrf-Token',
});

apiClient.interceptors.response.use(
  response => response,
  error => {
    const { data } = error.response;
    const { data: { meta } = {} } = error.response;
    /* eslint-disable-next-line prefer-promise-reject-errors */
    return Promise.reject({ data, ...meta });
  },
);

export default apiClient;
