import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@ubnt/react-button';
import { Modal, BasicToast, Text } from '@ubnt/ui-components';
import { FormGroup } from '@ubnt/react-forms';

import StepContent from 'components/StepContent';
import StepHeader from 'components/StepHeader';
import { API_STRINGS } from 'utils/api';

export default class UpdateDevice extends Component {
  state = {
    isConfirmSkipUpdateOpen: false,
  };

  updateDevice = () => {
    const { createNotification, removeNotification, updateDevice } = this.props;

    updateDevice()
      .then(() => {
        setTimeout(() => {
          this.gotoConfigure();
        }, 200);
      })
      .catch(({ msg }) => {
        const detailsId = API_STRINGS[msg];

        createNotification(
          <BasicToast
            duration={3000}
            details={detailsId ? <FormattedMessage id={detailsId} /> : undefined}
            onClose={(e, id) => removeNotification(id)}
            title={<FormattedMessage id="SETUP_ERROR_TITLE_UPDATE" />}
            type="danger"
            stateIndicator="danger"
          />,
        );
      });
  };

  skipUpdate = () => {
    const { skipUpdate } = this.props;

    skipUpdate();
    this.gotoConfigure();
  };

  gotoConfigure = () => {
    const { history } = this.props;
    history.push('../configure');
  };

  showConfirmSkipUpdate = () => {
    this.setState({ isConfirmSkipUpdateOpen: true });
  };

  hideConfirmSkipUpdate = () => {
    this.setState({ isConfirmSkipUpdateOpen: false });
  };

  render() {
    const { isConfirmSkipUpdateOpen } = this.state;

    const confirmSkipUpdateActions = [
      {
        text: <FormattedMessage id="COMMON_VALUE_CANCEL" />,
        variant: 'secondary',
        onClick: this.hideConfirmSkipUpdate,
      },
      {
        text: <FormattedMessage id="COMMON_VALUE_CONFIRM" />,
        variant: 'primary',
        onClick: this.skipUpdate,
      },
    ];

    return (
      <StepContent>
        <Modal
          isOpen={isConfirmSkipUpdateOpen}
          size="small"
          title={<FormattedMessage id="SETUP_MODAL_HEADER_CONFIRM_SKIP_UPDATE" />}
          actions={confirmSkipUpdateActions}
        >
          <Text>
            <FormattedMessage id="SETUP_MODAL_MESSAGE_CONFIRM_SKIP_UPDATE" />
          </Text>
        </Modal>
        <StepHeader titleId="SETUP_HEADER_TITLE_UPDATE_DEVICE" descriptionId="SETUP_HEADER_DESC_UPDATE_DEVICE" />
        <FormGroup>
          <Button variant="primary" onClick={this.updateDevice} type="button">
            <FormattedMessage id="SETUP_ACTION_UPDATE_DEVICE" />
          </Button>
        </FormGroup>
        <Button variant="link" onClick={this.showConfirmSkipUpdate} type="button">
          <FormattedMessage id="SETUP_ACTION_SKIP_UPDATE" />
        </Button>
      </StepContent>
    );
  }
}

UpdateDevice.propTypes = {
  createNotification: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  skipUpdate: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  updateDevice: PropTypes.func.isRequired,
};
