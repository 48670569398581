import get from 'lodash.get';
import { createSelector } from 'reselect';
import { createPaginatedSelector } from 'utils/pagination/selectors';
import { updateModelName, filterNonAdoptable } from './utils';

export const selectDevices = state => get(state, 'models.devices.data');
export const selectDeviceList = createSelector(
  selectDevices,
  devices =>
    devices
      .map(item => ({ ...item, id: item.mac }))
      .map(updateModelName)
      .filter(filterNonAdoptable),
);

export const selectPaginatedDeviceList = createPaginatedSelector(selectDeviceList);
