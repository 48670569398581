import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { Provider } from 'react-redux';
import { Global, css } from '@emotion/core';
import '@ubnt/aura/dist/base.css';
import '@ubnt/aura/dist/fonts.css';
import '@ubnt/aura/dist/default.theme.css';

import IntlProvider from 'components/IntlProvider';
import store from './store';
import theme from './theme';
import App from './components/App';

const globalStyles = css`
  html,
  body,
  #root {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
`;

const root = document.getElementById('root');
const tree = (
  <>
    <Global styles={globalStyles} />
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <IntlProvider>
          <Router basename="/setup/">
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          </Router>
        </IntlProvider>
      </ThemeProvider>
    </Provider>
  </>
);
const load = () => render(tree, root);

load();
