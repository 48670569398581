import Button from '@ubnt/ui-components/Button/Button';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Link = ({ href, children }) => {
  return (
    <Button
      variant="inline"
      type="button"
      onClick={e => {
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        window.open(href, '_blank', 'noopener,noreferrer');
      }}
    >
      {children}
    </Button>
  );
};

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};

Link.defaultProps = {
  href: '',
  children: null,
};

export const FormattedLink = ({ messageId, ...props }) => (
  <Link {...props}>
    <FormattedMessage id={messageId} />
  </Link>
);

FormattedLink.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default Link;
