import Credentials from '../components/Credentials';
import ControllerName from '../components/ControllerName';

const steps = [
  {
    id: 'controller-name',
    Component: ControllerName,
  },
  {
    id: 'credentials',
    Component: Credentials,
    props: {
      switchToAdvancedFlow: true,
    },
  },
];

export default {
  id: 'defaultFlow',
  steps,
};
