import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Flex from '@ubnt/react-flex';
import Text from '@ubnt/react-text';

export default function ReviewItem({ labelId, value, renderValue, ...rest }) {
  const Value = renderValue;
  return (
    value && (
      <Flex justify="between" align="center" className="mb-4" {...rest}>
        <Text>
          <FormattedMessage id={labelId} />
        </Text>
        <Value value={value} />
      </Flex>
    )
  );
}

ReviewItem.defaultProps = {
  value: null,
};

ReviewItem.propTypes = {
  labelId: PropTypes.string.isRequired,
  value: PropTypes.any,
  renderValue: PropTypes.func.isRequired,
};
