// TODO Extract to shared library

import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { Toggle as LibToggle } from '@ubnt/react-forms';

import { fieldValueValidation } from '../utils/formikUtils';

const toggleClassName = css`
  label {
    --toggle-border-width: 0px;
  }
`;

function Toggle({ field: { name, onChange, value }, afterChange, ...rest }) {
  return (
    <LibToggle
      id={name}
      small
      name={name}
      className={toggleClassName.name}
      onChange={(val, e) => {
        onChange(e);
        if (afterChange) afterChange(val);
      }}
      checked={rest.checked || value}
      value={rest.value || value}
      {...rest}
    />
  );
}

Toggle.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: fieldValueValidation((props, propName) => typeof props[propName] === 'boolean'),
  }).isRequired,
  afterChange: PropTypes.func,
};

Toggle.defaultProps = {
  afterChange: null,
};

export default React.memo(Toggle);
