import { reduceModelHandler } from 'utils/model';
import { SELF_LOGOUT } from './actions';

const initialState = {};

export default function self(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SELF_LOGOUT:
      return reduceModelHandler(state, action);

    default:
      return state;
  }
}
