/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Formik } from 'formik';

import StepButton from './components/StepButton';
import BackButton from './components/BackButton';

const { Provider, Consumer } = React.createContext({});

const ActionContainer = styled.div`
  position: absolute;
  left: 0;
  right: 64px;
  bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

const StyledFormContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const LeftFooterSpace = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
`;

const StyledForm = styled.form`
  /* keep form center and push next button to bottom of container */
  display: flex;
  width: 100%;
  height: 100%;

  flex-direction: column;
  justify-content: center;
`;

class MultiStepFormComponent extends Component {
  state = {
    steps: [],
    step: {
      id: null,
      title: null,
      disabled: false,
    },
  };

  clickHandler = null;

  disabled = false;

  componentDidMount() {
    const {
      formikProps: { values },
      routerProps: {
        history,
        match: {
          params: { step: routedStep },
        },
      },
    } = this.props;
    const { children } = this.props;
    const steps = React.Children.map(children, ({ props: { children, render, ...config } }) => config).filter(
      step => !step.hidden || (step.hidden && !step.hidden(values)),
    );
    const [activeStep] = steps.filter(s => s.id === routedStep);
    // if url matches a step in the middle of a flow, kick them to back first step
    if (steps.indexOf(activeStep) !== 0) {
      history.push(steps[0].id);
    }
  }

  get ids() {
    const { steps } = this.state;
    return steps.map(s => s.id);
  }

  get nextStep() {
    const { step } = this.state;
    return this.ids[this.ids.indexOf(step.id) + 1];
  }

  get prevStep() {
    const { step } = this.state;
    return this.ids[this.ids.indexOf(step.id) - 1];
  }

  get totalSteps() {
    return this.ids.length;
  }

  get activeChild() {
    const { children } = this.props;
    const {
      step: { id: activeId },
    } = this.state;
    const [child = null] = React.Children.toArray(children).filter(({ props: { id } }) => id === activeId);
    return child;
  }

  static getDerivedStateFromProps = props => {
    const {
      formikProps: { values },
      routerProps: {
        history,
        match: {
          params: { step: routedStep },
        },
      },
    } = props;
    const steps = React.Children.map(props.children, ({ props: { children, render, ...config } }) => config).filter(
      step => !step.hidden || (step.hidden && !step.hidden(values)),
    );

    let [activeStep] = steps.filter(s => s.id === routedStep);

    if (!activeStep) {
      [activeStep] = steps;
      history.push(`${activeStep.id}`);
    }

    return { steps, step: activeStep };
  };

  isDone = () => {
    const {
      step: { id: activeId },
    } = this.state;
    return this.ids.indexOf(activeId) + 1 === this.ids.length;
  };

  next = (skip = false) => {
    const {
      formikProps: { handleSubmit },
      routerProps: { history },
    } = this.props;

    if (this.isDone()) {
      this.disabled = false;
      handleSubmit();
      return;
    }

    const { steps } = this.state;
    if (!skip && this.disabled) return;
    const nextStep = steps.filter(s => s.id === this.nextStep)[0];
    history.push(nextStep.id);
    this.setState({ step: nextStep });
    this.disabled = false;
    this.clickHandler = null;
  };

  skip = e => {
    const {
      formikProps: { handleSubmit },
    } = this.props;
    if (this.isDone()) {
      this.disabled = false;
      handleSubmit(e);
      return;
    }
    this.next(true);
  };

  prev = () => {
    const { steps } = this.state;
    const {
      routerProps: { history },
    } = this.props;
    const prevStep = steps.filter(s => s.id === this.prevStep)[0];
    history.push(prevStep.id);
    this.setState({ step: prevStep });
    this.disabled = false;
    this.clickHandler = null;
  };

  goToStep = id => {
    const {
      routerProps: { history },
    } = this.props;
    const { steps } = this.state;
    const step = steps.filter(s => s.id === id)[0];
    if (!step) return;
    history.push(step.id);
    this.setState({ step });
    this.disabled = false;
    this.clickHandler = null;
  };

  onSubmit = e => {
    const {
      formikProps: { handleSubmit },
    } = this.props;

    e.preventDefault();
    if (this.disabled) return;

    if (this.clickHandler) {
      this.clickHandler();
    } else if (this.isDone()) {
      handleSubmit(e);
    } else {
      this.next();
    }
  };

  renderControls = ({
    loading = false,
    error = false,
    success = false,
    onPrevClicked = this.prev,
    onSkipClicked = this.skip,
    disabled,
    clickHandler,
    label,
    variant = 'primary',
    hidePrevious = false,
    hideNext = false,
    hideSkip = true,
    customChildren = null,
  } = {}) => {
    const {
      step: { id: activeId },
    } = this.state;

    const activeIdx = this.ids.indexOf(activeId);
    const currStep = activeIdx + 1;
    const done = currStep === this.ids.length;
    const start = activeIdx === 0;
    const btnText = label || (done ? 'Finish' : 'Next');

    this.disabled = disabled || false;
    this.clickHandler = clickHandler || null;

    return (
      <ActionContainer>
        {customChildren && <LeftFooterSpace>{customChildren}</LeftFooterSpace>}
        {!start && !hidePrevious && <BackButton onClick={onPrevClicked} />}
        {!hideSkip && <StepButton type="button" onClick={onSkipClicked} label="Skip" variant="secondary" />}
        {!hideNext && (
          <StepButton
            type="submit"
            variant={variant}
            disabled={!!disabled}
            loading={loading}
            error={error}
            success={success}
            label={btnText}
          />
        )}
      </ActionContainer>
    );
  };

  render() {
    const { validationSchema, formikProps } = this.props;
    const {
      step: { id: activeId, ...stepProps },
    } = this.state;

    return (
      <StyledFormContainer>
        <StyledForm onSubmit={this.onSubmit}>
          <Provider
            value={{
              validationSchema,
              ...stepProps,
              ...formikProps,
              ...{
                next: this.next,
                prev: this.prev,
                skip: this.skip,
                renderControls: this.renderControls,
                goToStep: this.goToStep,
              },
            }}
          >
            {this.activeChild}
          </Provider>
        </StyledForm>
      </StyledFormContainer>
    );
  }
}

export default function MultiStepForm({ children, handleSubmit, history, match, location, ...props }) {
  return (
    <Formik
      {...props}
      handleSubmit={handleSubmit}
      onSubmit={handleSubmit}
      render={renderProps => (
        <MultiStepFormComponent {...props} formikProps={renderProps} routerProps={{ match, history, location }}>
          {children}
        </MultiStepFormComponent>
      )}
    />
  );
}

export function Step({ render }) {
  return <Consumer>{render}</Consumer>;
}
