/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';

export default (lazyRendererPromise, PlaceholderComponent = () => null) => WrappedComponent =>
  class extends Component {
    state = {
      loaded: false,
    };

    componentDidMount() {
      Promise.resolve(lazyRendererPromise(this.props)).then(() => {
        if (!this.isCanceled) {
          this.setState({ loaded: true });
        }
      });
    }

    componentWillUnmount() {
      this.isCanceled = true;
    }

    render() {
      const { loaded } = this.state;
      return loaded ? <WrappedComponent {...this.props} /> : <PlaceholderComponent />;
    }
  };
