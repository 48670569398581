import AdvancedAccess from '../components/AdvancedAccess';
import Credentials from '../components/Credentials';
import ControllerName from '../components/ControllerName';

const steps = [
  {
    id: 'controller-name',
    Component: ControllerName,
  },
  {
    id: 'credentials',
    Component: Credentials,
    hidden: ({ cloudAccessEnabled, ubiquitiLoginEnabled }) => !cloudAccessEnabled && !ubiquitiLoginEnabled,
  },
  {
    id: 'advanced-access',
    Component: AdvancedAccess,
  },
];

export default {
  id: 'advancedFlow',
  props: {
    flowTitleId: 'SETUP_FLOW_TITLE_ADVANCED',
  },
  steps,
};
