import styled from '@emotion/styled';

export default styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: ${({ noSidebar }) => (noSidebar ? 'center' : 'flex-start')};
  overflow-y: auto;
  overflow-x: hidden;
`;
