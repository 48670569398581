import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import UbiquitiLogo from '@ubnt/icons/UbiquitiLogo';

import Border from 'components/Border';
import DeviceImage from 'components/DeviceImage';
import DeviceImageWrapper from 'components/DeviceImageWrapper';
import Page from 'components/Page';
import { SIDE_IMAGE_TYPE } from 'state/ui/constants';

const LogoContainer = styled.div`
  margin: 14px;
`;

export default function PageWrapper({ children, noSidebar, sideImage }) {
  return (
    <Page noSidebar>
      <LogoContainer>
        <UbiquitiLogo variant="aqua" size="header" />
      </LogoContainer>
      {!noSidebar && (
        <DeviceImageWrapper>
          <DeviceImage image={sideImage} />
          <Border />
        </DeviceImageWrapper>
      )}
      {children}
    </Page>
  );
}

PageWrapper.defaultProps = {
  noSidebar: false,
  sideImage: SIDE_IMAGE_TYPE.DEFAULT,
  children: null,
};

PageWrapper.propTypes = {
  children: PropTypes.node,
  noSidebar: PropTypes.bool,
  sideImage: PropTypes.oneOf(Object.values(SIDE_IMAGE_TYPE)),
};
