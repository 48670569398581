import { connect } from 'react-redux';

import { selectCountryOptions } from 'state/models/countries';
import ReviewValueCountry from './ReviewValueCountry';

const mapStateToProps = state => ({
  countryOptions: selectCountryOptions(state),
});

export default connect(mapStateToProps)(ReviewValueCountry);
