import React from 'react';
import { API_STRINGS } from 'utils/api';
import { FormattedMessage } from 'react-intl';
import { NotificationToast } from '@ubnt/ui-components';

export const showApiErrorToast = (createNotification, titleId, removeNotification) => error => {
  !!error &&
    !!error.msg &&
    createNotification(
      <NotificationToast
        details={<FormattedMessage id={API_STRINGS[error.msg]} values={error} />}
        onClose={(e, id) => removeNotification(id)}
        title={<FormattedMessage id={titleId} />}
        type="danger"
      />,
    );
};
