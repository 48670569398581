import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import Modal from '@ubnt/ui-components/Modal/Modal';
import Text from '@ubnt/ui-components/Text/Text';
import UserAccountIcon from '@ubnt/icons/UserAccountIconL';
import InternetIcon from '@ubnt/icons/InternetIcon';
import SecurityIcon from '@ubnt/icons-5/SecurityIcon';
import NavBell from '@ubnt/icons-5/NavBell';

const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
`;

const IconItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const IconContainer = styled.div`
  margin-right: 10px;
`;

const ListItem = ({ id, Icon }) => (
  <IconItemContainer>
    {Icon && (
      <IconContainer>
        <Icon size="large" variant="twoTone" isActive />
      </IconContainer>
    )}
    <Text size="header-xs" color="tertiary">
      <FormattedMessage id={id} />
    </Text>
  </IconItemContainer>
);

ListItem.propTypes = {
  id: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
};

ListItem.defaultProps = {
  Icon: null,
};

export function CreateAccountInfoModal({ isOpen, onRequestClose, onSkip, onProceed }) {
  return (
    <Modal
      size="small"
      isOpen={isOpen}
      allowOverflow
      onRequestClose={onRequestClose}
      title={<FormattedMessage id="SETUP_CREATE_ACCOUNT" />}
      actions={[
        {
          type: 'button',
          text: <FormattedMessage id="SETUP_SKIP" />,
          onClick: onSkip,
        },
        {
          type: 'button',
          text: <FormattedMessage id="SETUP_ACCOUNT_POPUP_CREATE_BUTTON" />,
          onClick: onProceed,
          variant: 'primary',
        },
      ]}
    >
      <IconHeader>
        <UserAccountIcon variant="twoTone" isActive size="header" />
      </IconHeader>
      <Text size="header-xs" color="tertiary">
        <ListItem id="SETUP_ACCOUNT_POPUP_DESC_1" />
        <ListItem Icon={InternetIcon} id="SETUP_ACCOUNT_POPUP_DESC_2" />
        <ListItem Icon={SecurityIcon} id="SETUP_ACCOUNT_POPUP_DESC_3" />
        <ListItem Icon={NavBell} id="SETUP_ACCOUNT_POPUP_DESC_4" />
      </Text>
    </Modal>
  );
}

CreateAccountInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
};
