import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup } from '@ubnt/react-forms';

import StepContent from 'components/StepContent';
import StepHeader from 'components/StepHeader';
import { Field } from 'lib/formik/components';
import { skipWifiSetup, validateFields } from 'utils/formik';

const CHECK_FIELDS = ['wifiName', 'wifiPassword', 'wifiSsidCombine'];

export default function WifiSetup({ initialValues, renderControls, setFieldValue, values, validationSchema, skip }) {
  const disabled = !validateFields(validationSchema, values, CHECK_FIELDS);
  const hideSkip = !skipWifiSetup(values);

  return (
    <StepContent>
      <StepHeader titleId="SETUP_HEADER_TITLE_WIFI_SETUP" descriptionId="SETUP_HEADER_DESC_WIFI_SETUP" />
      <FormGroup>
        <Field name="wifiName" label="SETUP_LABEL_WIFI_NAME" autoFocus />
        <Field name="wifiPassword" label="SETUP_LABEL_WIFI_PASSWORD" type="password" passwordToggle />
      </FormGroup>
      {renderControls({
        disabled,
        hideSkip,
        onSkipClicked: e => {
          setFieldValue('wifiName', initialValues.wifiName, false);
          setFieldValue('wifiPassword', initialValues.wifiPassword, false);
          setFieldValue('wifiSsidCombine', initialValues.wifiSsidCombine, false);

          skip(e);
        },
      })}
    </StepContent>
  );
}

WifiSetup.propTypes = {
  initialValues: PropTypes.object.isRequired,
  renderControls: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  skip: PropTypes.func.isRequired,
};
