export const DEVICE_STATE = {
  DISCONNECTED: 0,
  CONNECTED: 1,
  PENDING: 2,
  FIRMWARE_MISMATCH: 3,
  UPGRADING: 4,
  PROVISIONING: 5,
  HEARTBEAT_MISSED: 6,
  ADOPTING: 7,
  DELETING: 8,
  INFORM_ERROR: 9,
  ADOPTION_FAILED: 10,
  ISOLATED: 11,
};
