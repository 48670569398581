import apiClient from 'utils/api/apiClient';

export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const updateAdmin = ({ username, password }) => dispatch => {
  const promise = apiClient.put('/api/self', {
    name: username,
    x_password: password,
  });

  dispatch({ type: UPDATE_ADMIN });

  return promise;
};
