import React from 'react';

import { ToastConsumer } from '@ubnt/ui-components';

export default WrappedComponent => props => {
  return (
    <ToastConsumer>
      {({ createNotification, removeNotification, clearNotifications }) => (
        <WrappedComponent
          createNotification={createNotification}
          removeNotification={removeNotification}
          clearNotifications={clearNotifications}
          {...props}
        />
      )}
    </ToastConsumer>
  );
};
