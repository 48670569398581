import { handle } from 'redux-pack';
import get from 'lodash.get';

const reduceHandler = (state, action, payloadExtractor, fields) => {
  const { payload } = action;
  const { loading, error } = { loading: 'loading', error: 'error', ...fields };

  return handle(state, action, {
    start: prevState => ({ ...prevState, [loading]: true, [error]: null }),
    success: prevState => ({ ...prevState, ...payloadExtractor(payload, action) }),
    failure: prevState => {
      const { data, ...rest } = payload;
      return { ...prevState, ...payloadExtractor(payload, action), [error]: rest };
    },
    finish: prevState => ({ ...prevState, [loading]: false }),
  });
};

const modelPayloadExtractor = response => ({ data: get(response, 'data.data[0]', {}) });

const collectionExtractor = response => ({ data: get(response, 'data.data', get(response, 'data', [])) });

const collectionV2Extractor = response => ({ data: get(response, 'data', []) });

const restoreBackendStatusPayloadExtractor = response => ({
  isBackendUp: get(response, 'data.meta.up', false),
});

const chooseBackupPayloadExtractor = (response, action) => ({
  restoreBackupInfo: {
    backup_id: response.data.data[0].backup_id,
    filename: action.meta.filename,
    timestamp: action.meta.time,
    version: action.meta.version,
  },
});

const restoreBackupInfoPayloadExtractor = response => ({ restoreBackupInfo: response.data.meta });

export const reduceModelHandler = (state, action) => reduceHandler(state, action, modelPayloadExtractor);

export const reduceCollectionHandler = (state, action, fields) =>
  reduceHandler(state, action, collectionExtractor, fields);

export const reduceCollectionV2Handler = (state, action, fields) =>
  reduceHandler(state, action, collectionV2Extractor, fields);

export const reduceChooseBackupHandler = (state, action) =>
  reduceHandler(state, action, chooseBackupPayloadExtractor, { loading: 'choosingBackup' });

export const reduceRestoreBackupInfoHandler = (state, action) =>
  reduceHandler(state, action, restoreBackupInfoPayloadExtractor);

export const reduceBackedStatusHandler = (state, action) =>
  reduceHandler(state, action, restoreBackendStatusPayloadExtractor);
