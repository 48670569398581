// TODO Extract to shared library

import React from 'react';
import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import { css } from 'emotion';
import cx from 'classnames';
import Label from '@ubnt/react-label';

const inlineBlock = css`
  display: inline-block;
`;
export default function TextArea({
  className,
  full,
  id,
  invalid,
  name,
  label,
  onChange,
  secondary,
  style,
  textAreaStyle,
  textAreaClassName,
  value,
  ...rest
}) {
  const hasLabel = !!label;

  const parentAuraClasses = cx(
    {
      'width-full': full,
      'is-filled': value !== undefined && value !== null && value !== '',
      'ubntForm--secondary': secondary,
    },
    className,
  );
  const textareaAuraClasses = cx(
    {
      'is-invalid': invalid,
      'width-full': full,
      'py-2': secondary,
    },
    textAreaClassName,
  );

  return (
    <div style={style} className={parentAuraClasses}>
      {hasLabel ? (
        <Label htmlFor={id} className={`mb-4 ${inlineBlock}`}>
          <FormattedMessage id={label} />
        </Label>
      ) : null}
      <textarea
        {...rest}
        id={id}
        name={name}
        className={textareaAuraClasses}
        onChange={e => onChange && onChange(e.target.value, e)}
        value={value}
      />
    </div>
  );
}

TextArea.propTypes = {
  className: propTypes.oneOfType([propTypes.string, propTypes.array, propTypes.object]),
  full: propTypes.bool,
  id: propTypes.string,
  invalid: propTypes.bool,
  label: propTypes.string,
  onBlur: propTypes.func,
  onChange: propTypes.func.isRequired,
  onFocus: propTypes.func,
  name: propTypes.string.isRequired,
  secondary: propTypes.bool,
  style: propTypes.object,
  textAreaStyle: propTypes.object,
  textAreaClassName: propTypes.oneOfType([propTypes.string, propTypes.array, propTypes.object]),
  value: propTypes.string.isRequired,
};

TextArea.defaultProps = {
  className: null,
  full: false,
  id: null,
  invalid: false,
  label: null,
  onBlur: Function.prototype,
  onFocus: Function.prototype,
  secondary: false,
  style: null,
  textAreaClassName: null,
  textAreaStyle: null,
};
