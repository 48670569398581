import get from 'lodash.get';

import { findHardwareInfo, isConsole } from './utils';

export const selectSysInfo = state => get(state, 'models.sysInfo.data');
export const selectUbntDeviceType = state => get(selectSysInfo(state), 'ubnt_device_type');
export const selectHardwareInfo = state => findHardwareInfo(selectUbntDeviceType(state));
export const selectIsConsole = state => isConsole(selectHardwareInfo(state));
export const selectDeviceName = state => get(selectHardwareInfo(state), 'name');
export const selectIsCloudKey = state => !!get(selectSysInfo(state), 'cloudkey_version');
export const selectIsUpdateAvailable = state => !!get(selectSysInfo(state), 'cloudkey_update_available');
export const selectIsUpdating = state => !!get(selectSysInfo(state), 'cloudkey_fw_updating');
export const selectIsUnifiGo = state => !!get(selectSysInfo(state), 'unifi_go_enabled');
