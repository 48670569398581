import Yup from 'utils/yup';

export default Yup.object().shape({
  ubiquitiAccount: Yup.string()
    .label('SETUP_LABEL_UBIQUITI_ACCOUNT')
    .required(),
  ubiquitiPassword: Yup.string()
    .label('SETUP_LABEL_UBIQUITI_PASSWORD')
    .required(),
  ubiquiti2faTokenRequired: Yup.boolean(),
  ubiquiti2faToken: Yup.string().when('ubiquiti2faTokenRequired', {
    is: true,
    then: Yup.string()
      .label('SETUP_LABEL_UBIQUITI_2FA_TOKEN')
      .required(),
  }),
});
