import { reduceModelHandler } from 'utils/model';
import { FETCH_CLOUD_ACCESS } from './actions';

const initialState = {};

export default function cloudAccess(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case FETCH_CLOUD_ACCESS:
      return reduceModelHandler(state, action);

    default:
      return state;
  }
}
