import apiClient from 'utils/api/apiClient';

export const FETCH_SYS_INFO = 'FETCH_SYS_INFO';
export const fetchSysInfo = () => dispatch => {
  const promise = apiClient.get('/api/stat/sysinfo');

  dispatch({ type: FETCH_SYS_INFO, promise });

  return promise;
};
