import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withToast from 'hoc/withToast';

import { selectIsConsole } from 'state/models/sysInfo';
import {
  discardBackup,
  selectError,
  getBackendStatus,
  selectBackendStatus,
  selectLoadingBackup,
  selectRestoringBackup,
  selectUploadedBackupInfo,
  restoreBackup,
  uploadBackup,
} from 'state/models/backups';
import RestoreBackupModals from './RestoreBackupModals';

const mapDispatchToProps = {
  discardBackup,
  getBackendStatus,
  restoreBackup,
  uploadBackup,
};

const mapStateToProps = state => ({
  isBackendUp: selectBackendStatus(state),
  isConsole: selectIsConsole(state),
  restoreBackupInfo: selectUploadedBackupInfo(state),
  restoringBackup: selectRestoringBackup(state),
  error: selectError(state),
  isChoosing: selectLoadingBackup(state, 'choosingBackup'),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
  withToast,
)(RestoreBackupModals);
