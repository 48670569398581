import React from 'react';
import PropTypes from 'prop-types';

import Button from '@ubnt/ui-components/Button/Button';
import CheckCircleFilledIcon from '@ubnt/icons-5/CheckCircleFilledIcon';
import SpinnerIcon from '@ubnt/icons-5/SpinnerIcon';
import ToastFailureIcon from '@ubnt/icons-5/ToastFailureIcon';

const getIcon = (loading, success, error) => {
  if (loading) return SpinnerIcon;
  if (success) return CheckCircleFilledIcon;
  if (error) return ToastFailureIcon;
  return null;
};

export default function StepButton(props) {
  const { label, loading, success, error, ...rest } = props;

  if (loading || success || error) {
    const Icon = getIcon(loading, success, error);
    return <Button Icon={Icon} {...rest} />;
  }
  return <Button {...rest}>{label}</Button>;
}

StepButton.defaultProps = {
  loading: false,
  error: false,
  success: false,
};

StepButton.propTypes = {
  label: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
};
