import apiClient from 'utils/api/apiClient';
import { randomString } from 'utils/random';

export const FETCH_DEVICES = 'FETCH_DEVICES';
export const fetchDevices = () => dispatch => {
  const promise = apiClient.post('/api/stat/device');

  dispatch({ type: FETCH_DEVICES, promise });

  return promise;
};

export const ADOPT_DEVICES = 'ADOPT_DEVICES';
export const adoptDevices = ({ macs }) => dispatch => {
  const promise = apiClient.post('/api/cmd/devmgr', {
    cmd: 'adopt',
    macs,
  });

  dispatch({ type: ADOPT_DEVICES });

  return promise;
};

export const SET_DEVICE_AUTH = 'SET_DEVICE_AUTH';
export const setDeviceAuth = ({ username }) => dispatch => {
  const promise = apiClient.post('/api/set/setting/mgmt', {
    x_ssh_username: username,
    x_ssh_password: randomString(16),
  });

  dispatch({ type: SET_DEVICE_AUTH });

  return promise;
};
