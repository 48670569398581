import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { Modal } from '@ubnt/ui-components';

import Image from 'components/Image';
import { mfaEmail, mfaPush, mfaSms, mfaTotp } from 'assets/images/pages';
import ArrowRightIcon from '@ubnt/icons-5/ArrowRightIcon';
import * as PropTypes from 'prop-types';

const AuthenticatorsList = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 74px;
  max-height: calc(100vh - 240px);
`;

const Authenticator = styled('div')`
  display: flex;
  padding: 16px 10px;

  &:not(:first-of-type) {
    border-top: 1px solid #e5e7ed;
    margin-top: 8px;
    padding-top: 24px;
  }

  &:empty {
    display: none;
  }
`;

const MfaImage = styled('div')`
  margin-right: 16px;
`;

const MfaDescription = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MfaMethodType = styled('div')`
  font-size: 14px;
  line-height: 19px;
  color: #333543;
`;

const MfaMethodName = styled('div')`
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #7e8190;
`;

const MfaArrowImage = styled('div')`
  flex: 1;
  text-align: right;
  align-self: center;
`;

function MfaChangeAuthenticatorModal(props) {
  const { authenticators, authenticator, isOpen, onChange, onCancel } = props;
  const availableAuthenticators = authenticators.filter(a => a.id !== authenticator.id);

  const renderAuthenticator = (icon, methodTypeTranslationKey, methodName) => {
    return (
      <>
        <MfaImage>
          <Image image={icon} alt={''} width={40} height={40} />
        </MfaImage>
        <MfaDescription>
          <MfaMethodType>
            <FormattedMessage id={methodTypeTranslationKey} />
          </MfaMethodType>
          <MfaMethodName>{methodName}</MfaMethodName>
        </MfaDescription>
        <MfaArrowImage>
          <ArrowRightIcon width={11} height={11} />
        </MfaArrowImage>
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      size="small"
      title={<FormattedMessage id="SETUP_MFA_MODAL_HEADER_OTHER_METHODS" />}
      actions={[
        {
          text: <FormattedMessage id="COMMON_VALUE_CANCEL" />,
          variant: 'secondary',
          onClick: () => onCancel(),
        },
      ]}
    >
      <p>
        <FormattedMessage id="SETUP_MFA_MODAL_INFO_OTHER_METHODS" />
      </p>
      <AuthenticatorsList>
        {availableAuthenticators.map(auth => (
          <Authenticator key={auth.id} onClick={() => onChange(auth)}>
            {auth.type === 'push' && renderAuthenticator(mfaPush, 'SETUP_MFA_MODAL_METHOD_PUSH', auth.name)}
            {auth.type === 'totp' && renderAuthenticator(mfaTotp, 'SETUP_MFA_MODAL_METHOD_TOTP', auth.name)}
            {auth.type === 'sms' && renderAuthenticator(mfaSms, 'SETUP_MFA_MODAL_METHOD_SMS', auth.phone_number)}
            {auth.type === 'email' && renderAuthenticator(mfaEmail, 'SETUP_MFA_MODAL_METHOD_EMAIL', auth.email)}
          </Authenticator>
        ))}
      </AuthenticatorsList>
    </Modal>
  );
}

MfaChangeAuthenticatorModal.propTypes = {
  authenticators: PropTypes.any.isRequired,
  authenticator: PropTypes.any.isRequired,
  isOpen: PropTypes.any.isRequired,
  onChange: PropTypes.any.isRequired,
  onCancel: PropTypes.any.isRequired,
};

export default MfaChangeAuthenticatorModal;
