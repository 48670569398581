import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@ubnt/ui-components/Button/Button';

export default function BackButton(props) {
  const { onClick } = props;

  return (
    <Button onClick={onClick} variant="tertiary" type="button">
      <FormattedMessage id="COMMON_ACTION_BACK" />
    </Button>
  );
}

BackButton.defaultProps = {
  onClick: Function.prototype,
};

BackButton.propTypes = {
  onClick: PropTypes.func,
};
