import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import memoizeOne from 'memoize-one';
import { Modal } from '@ubnt/ui-components';

import { showApiErrorToast } from 'utils/api';

const getActions = memoizeOne((discardBackup, restoreBackup, backupId) => [
  {
    text: <FormattedMessage id="COMMON_VALUE_CANCEL" />,
    variant: 'secondary',
    onClick: () => discardBackup(backupId),
  },
  {
    text: <FormattedMessage id="COMMON_VALUE_CONFIRM" />,
    variant: 'danger',
    onClick: () => restoreBackup(backupId),
  },
]);

export default class RestoreBackupModals extends Component {
  getBackendStatusTask = null;

  componentDidUpdate() {
    const { isBackendUp } = this.props;

    if (isBackendUp) {
      clearInterval(this.getBackendStatusTask);
      window.location.reload();
    }
  }

  discardBackup = backupId => {
    const { createNotification, discardBackup, removeNotification } = this.props;
    discardBackup(backupId).catch(
      showApiErrorToast(createNotification, 'SETUP_ERROR_TITLE_RESTORE', removeNotification),
    );
  };

  restoreBackup = backupId => {
    const { createNotification, getBackendStatus, removeNotification, restoreBackup } = this.props;

    restoreBackup(backupId)
      .then(() => {
        this.getBackendStatusTask = setInterval(getBackendStatus, 2000);
      })
      .catch(showApiErrorToast(createNotification, 'SETUP_ERROR_TITLE_RESTORE', removeNotification));
  };

  render() {
    const { isConsole, intl, isChoosing, restoringBackup, restoreBackupInfo } = this.props;

    const { backup_id, timestamp } = restoreBackupInfo || {};

    const actions = getActions(this.discardBackup, this.restoreBackup, backup_id);

    return (
      <>
        <Modal
          isOpen={!restoringBackup && !!backup_id}
          size="small"
          title={<FormattedMessage id="SETUP_HEADER_TITLE_RESTORE_FROM_BACKUP" />}
          actions={actions}
        >
          {restoreBackupInfo && (
            <FormattedHTMLMessage
              id="SETUP_BACKUP_RESTORE_MODAL_MESSAGE"
              values={{
                datetime: timestamp
                  ? `${intl.formatDate(parseInt(timestamp, 10))} ${intl.formatTime(parseInt(timestamp, 10))}`
                  : '',
                ...restoreBackupInfo,
              }}
            />
          )}
        </Modal>
        <Modal isOpen={restoringBackup} size="small">
          <FormattedMessage id="SETUP_BACKUP_RESTORING_MODAL_MESSAGE" values={{ isConsole }} />
        </Modal>
        <Modal isOpen={isChoosing} size="small">
          <FormattedMessage id="SETUP_BACKUP_LOADING_MODAL_MESSAGE" />
        </Modal>
      </>
    );
  }
}

RestoreBackupModals.defaultProps = {
  restoreBackupInfo: null,
  isBackendUp: false,
  isChoosing: false,
};

RestoreBackupModals.propTypes = {
  createNotification: PropTypes.func.isRequired,
  discardBackup: PropTypes.func.isRequired,
  getBackendStatus: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isBackendUp: PropTypes.bool,
  isChoosing: PropTypes.bool,
  removeNotification: PropTypes.func.isRequired,
  restoreBackupInfo: PropTypes.object,
  restoreBackup: PropTypes.func.isRequired,
  restoringBackup: PropTypes.bool.isRequired,
};
