import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Button, Table, UploadButton } from '@ubnt/ui-components';
import UploadIcon from '@ubnt/icons/UploadIcon';

import { showApiErrorToast } from 'utils/api';
import Pagination from 'utils/pagination/components/Pagination';
import StepContent from 'components/StepContent';
import StepHeader from 'components/StepHeader';
import AnimatedDots from 'components/AnimatedDots';
import { COLUMNS } from './constants';

const Spacer = styled.div`
  height: 20px;
`;

const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
`;

export default class BackupList extends Component {
  state = {};

  componentDidMount = () => {
    const { fetchBackups, createNotification, removeNotification } = this.props;
    fetchBackups()
      .then(() => {
        this.setState({ loaded: true });
      })
      .catch(showApiErrorToast(createNotification, 'SETUP_ERROR_TITLE_RESTORE', removeNotification));
  };

  getRowsWithSelection = () => {
    const {
      paginatedBackups: { rows },
    } = this.props;
    const { selected } = this.state;
    return rows.map(row => ({
      ...row,
      selected: selected === row.id,
    }));
  };

  chooseBackup(row) {
    const { backupType, chooseBackup, createNotification, removeNotification } = this.props;
    const { device_id, filename, time, version } = row;
    chooseBackup(backupType, {
      deviceId: device_id,
      filename,
      time,
      version,
    }).catch(showApiErrorToast(createNotification, 'SETUP_ERROR_TITLE_RESTORE', removeNotification));
  }

  render() {
    const {
      paginatedBackups,
      pagination,
      renderControls,
      history,
      uploadBackup,
      createNotification,
      removeNotification,
    } = this.props;
    const { currentPage, rows, totalPages, totalRows } = paginatedBackups;
    const { selected, loaded } = this.state;
    const selectedBackup = rows.find(({ id }) => id === selected);
    return (
      <StepContent>
        <StepHeader titleId="SETUP_HEADER_TITLE_BACKUP_LIST" marginBottom={20}>
          {loaded ? (
            <FormattedMessage id="SETUP_HEADER_DESC_BACKUP_LIST" />
          ) : (
            <>
              <FormattedMessage id="SETUP_TABLE_LOADING_BACKUPS_DETAILS" />
              <AnimatedDots />
            </>
          )}
        </StepHeader>
        <UploadButton
          onChange={(_, fileRef) => {
            if (!fileRef.current.files) {
              return;
            }
            uploadBackup(fileRef.current.files[0]).catch(
              showApiErrorToast(createNotification, 'SETUP_ERROR_TITLE_RESTORE', removeNotification),
            );
          }}
          size="medium"
          variant="inline"
          Icon={UploadIcon}
        >
          <FormattedMessage id="SETUP_JOURNEY_HEADER_UPLOAD_BACKUP_FILE" />
        </UploadButton>
        <Spacer />
        {loaded && !!totalRows && (
          <Table
            columns={COLUMNS}
            items={this.getRowsWithSelection()}
            width="80%"
            onRowClick={({ id }) => this.setState({ selected: id })}
            renderFooter={() => (
              <Pagination
                pagination={pagination}
                currentPage={currentPage}
                totalPages={totalPages}
                totalRows={totalRows}
              />
            )}
          />
        )}
        {renderControls({
          hideNext: true,
          customChildren: (
            <FooterButtons>
              <Button variant="link" type="button" onClick={() => history.push('../configure')}>
                <FormattedMessage id="SETUP_ACTION_CONFIGURE_FLOW" />
              </Button>
              <Button
                variant="primary"
                type="button"
                disabled={!selectedBackup}
                onClick={() => this.chooseBackup(selectedBackup)}
              >
                <FormattedMessage id="SETUP_ACTION_RESTORE" />
              </Button>
            </FooterButtons>
          ),
        })}
      </StepContent>
    );
  }
}

BackupList.propTypes = {
  backupType: PropTypes.string.isRequired,
  createNotification: PropTypes.func.isRequired,
  chooseBackup: PropTypes.func.isRequired,
  paginatedBackups: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  removeNotification: PropTypes.func.isRequired,
  renderControls: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  uploadBackup: PropTypes.func.isRequired,
  fetchBackups: PropTypes.func.isRequired,
};
