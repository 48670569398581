import apiClient from 'utils/api/apiClient';

export const CREATE_ADMIN = 'CREATE_ADMIN';
export const createAdmin = ({ username, password, email }) => dispatch => {
  const promise = apiClient.post('/api/cmd/sitemgr', {
    cmd: 'add-default-admin',
    name: username,
    email,
    x_password: password,
  });

  dispatch({ type: CREATE_ADMIN });

  return promise;
};
