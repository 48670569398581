import deviceModels from '@ubnt/unifi-network-constants/json/deviceModels.json';

import { DEVICE_STATE } from './constants';

export const updateModelName = deviceModel => {
  const deviceInfo = deviceModels[deviceModel.model] || {};
  return { ...deviceModel, modelName: deviceInfo.name };
};

export const filterNonAdoptable = deviceModel => {
  const notUdm = deviceModel.type !== 'udm';
  const notUlte = deviceModel.model !== 'ULTE';
  const isPendingAndNotManagedByOther = deviceModel.state === DEVICE_STATE.PENDING && deviceModel.default;

  return isPendingAndNotManagedByOther && notUdm && notUlte;
};
