import memoizeOne from 'memoize-one';

import {
  ReviewValueBoolean,
  ReviewValueCountry,
  ReviewValueText,
  ReviewValueTimezone,
  ReviewValueToggle,
} from './components';

const extractWifiNameIot = values => {
  const { wifiName } = values;
  if (!wifiName) {
    return '';
  }
  const suffix = '_IoT';
  const allowedNameLength = 32 - suffix.length;
  const truncatedName = wifiName.substring(0, allowedNameLength);
  return truncatedName + suffix;
};

const extractValue = (values, id) => values[id];

export const generateReviewItems = memoizeOne(isUnifiGo => {
  return [
    !isUnifiGo && {
      extractValue,
      id: 'controllerName',
      labelId: 'SETUP_REVIEW_LABEL_CONTROLLER_NAME',
      renderValue: ReviewValueText,
    },
    {
      extractValue,
      id: 'cloudAccessEnabled',
      labelId: 'SETUP_REVIEW_LABEL_REMOTE_ACCESS',
      renderValue: ReviewValueToggle,
    },
    !isUnifiGo && {
      extractValue,
      id: 'ubiquitiLoginEnabled',
      labelId: 'SETUP_REVIEW_LABEL_UBIQUITI_LOGIN',
      renderValue: ReviewValueBoolean,
    },
    !isUnifiGo && {
      extractValue,
      id: 'ubiquitiAccount',
      labelId: 'SETUP_REVIEW_LABEL_UBIQUITI_ACCOUNT',
      renderValue: ReviewValueText,
      isHidden: values => !values.cloudAccessEnabled && !values.ubiquitiLoginEnabled,
    },
    {
      extractValue,
      id: 'localAdminUsername',
      labelId: 'SETUP_REVIEW_LABEL_LOCAL_ADMIN_USERNAME',
      renderValue: ReviewValueText,
      isHidden: values => values.ubiquitiLoginEnabled,
    },
    {
      extractValue,
      id: 'wifiName',
      labelId: 'SETUP_REVIEW_LABEL_WIFI_NAME',
      renderValue: ReviewValueText,
    },
    {
      extractValue: extractWifiNameIot,
      id: 'wifiNameIot',
      labelId: 'SETUP_REVIEW_LABEL_WIFI_NAME_IOT',
      renderValue: ReviewValueText,
      isHidden: values => values.wifiSsidCombine,
    },
    {
      extractValue,
      id: 'countryCode',
      labelId: 'SETUP_REVIEW_LABEL_COUNTRY',
      renderValue: ReviewValueCountry,
    },
    {
      extractValue,
      id: 'timezone',
      labelId: 'SETUP_REVIEW_LABEL_TIMEZONE',
      renderValue: ReviewValueTimezone,
    },
  ].filter(Boolean);
});
