import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '@ubnt/react-forms';

import FEATURE_FLAGS from 'constants/FEATURE_FLAGS';
import LabelGroup from 'components/LabelGroup';
import StepContent from 'components/StepContent';
import StepHeader from 'components/StepHeader';
import ToggleLabel from 'components/ToggleLabel';
import { validateFields } from 'utils/formik';
import { Field } from 'lib/formik/components';

const CHECK_FIELDS = ['autoBackupEnabled', 'cloudBackupEnabled'];

function NetworkSetup({ isUnifiGo, renderControls, validationSchema, values }) {
  const disabled = !validateFields(validationSchema, values, CHECK_FIELDS);
  const { cloudAccessEnabled } = values;

  return (
    <StepContent>
      <StepHeader
        titleId="SETUP_HEADER_TITLE_UNIFI_NETWORK_SETUP"
        descriptionId="SETUP_HEADER_DESC_UNIFI_NETWORK_SETUP"
      />
      <FormGroup>
        {!isUnifiGo && (
          <LabelGroup>
            <ToggleLabel
              htmlFor="autoBackupEnabled"
              labelId="SETUP_LABEL_ENABLE_AUTO_BACKUP"
              descriptionId="SETUP_DESC_ENABLE_AUTO_BACKUP"
            />
            <Field name="autoBackupEnabled" type="toggle" />
          </LabelGroup>
        )}
        {FEATURE_FLAGS.CLOUD_BACKUP && !isUnifiGo && cloudAccessEnabled && (
          <LabelGroup>
            <ToggleLabel
              htmlFor="cloudBackupEnabled"
              labelId="SETUP_LABEL_ENABLE_CLOUD_BACKUP"
              descriptionId="SETUP_DESC_ENABLE_CLOUD_BACKUP"
            />
            <Field name="cloudBackupEnabled" type="toggle" />
          </LabelGroup>
        )}
      </FormGroup>
      {renderControls({ disabled })}
    </StepContent>
  );
}

NetworkSetup.propTypes = {
  isUnifiGo: PropTypes.bool.isRequired,
  renderControls: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default NetworkSetup;
