import styled from '@emotion/styled';
import Breakpoints from '../utils/breakpoints';

export default styled.div`
  @media (min-width: ${Breakpoints.TabletMaxWidth}) {
    height: 100%;
    width: 100%;
    position: absolute;
    border: 0; // fix ugly border on the left in Safari
    border-right: 1px solid #fff;
    border-image: linear-gradient(0deg, transparent, #d6d9e2 50%, transparent 100%);
    border-image-slice: 1;
    background: white;
    &:after {
      content: '';
      position: absolute;
      background-image: linear-gradient(0deg, transparent, #d6d9e2 50%, transparent);
      top: 0;
      right: 0;
      filter: blur(5px);
      height: 100%;
      width: 5px;
      z-index: -1;
      opacity: 0.6;
    }
  }
`;
