// TODO Extract to shared library

import React from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField, FastField } from 'formik';

import Checkbox from './Checkbox';
import Dropdown from './Dropdown';
import Toggle from './Toggle';
import ValidatedInputField from './ValidatedInputField';
import ValidatedTextArea from './ValidatedTextArea';

const getFormComponent = type => {
  switch (type) {
    case 'toggle':
      return Toggle;
    case 'checkbox':
      return Checkbox;
    case 'textarea':
      return ValidatedTextArea;
    case 'select':
      return Dropdown;
    default:
      return ValidatedInputField;
  }
};

const getFieldType = type => {
  switch (type) {
    case 'checkbox':
    case 'toggle':
      // formik Field is breaking with type="checkbox" so omit for now
      // see: https://github.com/jaredpalmer/formik/pull/1115
      return null;
    default:
      return type;
  }
};

export default function Field({ fast, type, name, ...rest }) {
  const FormComponent = getFormComponent(type);
  const fieldType = getFieldType(type);
  const FieldComponent = fast ? FastField : FormikField;

  const id = rest.id || name;
  return <FieldComponent component={FormComponent} type={fieldType} name={name} id={id} {...rest} />;
}

Field.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  fast: PropTypes.bool,
};

Field.defaultProps = {
  type: 'text',
  fast: false,
};
