import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  selectAuthenticators,
  selectDefaultMfaId,
  mfaResendCode,
  ssoStandardLogin,
  ssoPollLogin,
  selectUbiquitiUsername,
} from 'state/models/sso';
import { setSideImage } from 'state/ui/actions';

import { fetchCloudAccess } from 'state/models/cloudAccess';

import withToast from 'hoc/withToast';
import UbiquitiSignIn from './UbiquitiSignIn';

const mapStateToProps = state => ({
  defaultMfaId: selectDefaultMfaId(state),
  mfaAuthenticators: selectAuthenticators(state),
  username: selectUbiquitiUsername(state),
});

const mapDispatchToProps = {
  fetchCloudAccess,
  mfaResendCode,
  ssoStandardLogin,
  ssoPollLogin,
  setSideImage,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withToast,
)(UbiquitiSignIn);
