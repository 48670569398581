import {
  reduceBackedStatusHandler,
  reduceCollectionHandler,
  reduceChooseBackupHandler,
  reduceRestoreBackupInfoHandler,
} from 'utils/model';
import {
  CHOOSE_BACKUP,
  DISCARD_BACKUP,
  FETCH_BACKUPS,
  GET_BACKEND_STATUS,
  RESTORE_BACKUP,
  UPLOAD_BACKUP,
} from './actions';

const initialState = { data: [] };

export default function backups(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case FETCH_BACKUPS:
      return reduceCollectionHandler(state, action);

    case UPLOAD_BACKUP:
      return reduceRestoreBackupInfoHandler(state, action);

    case CHOOSE_BACKUP:
      return reduceChooseBackupHandler(state, action);

    case DISCARD_BACKUP:
      return { ...state, restoreBackupInfo: undefined };

    case RESTORE_BACKUP:
      return { ...state, restoringBackup: true };

    case GET_BACKEND_STATUS:
      return reduceBackedStatusHandler(state, action);

    default:
      return state;
  }
}
