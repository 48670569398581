import { reduceModelHandler } from 'utils/model';
import { SSO_LOGIN, SSO_LOGIN_MFA, SSO_MFA_RESEND_CODE } from './actions';

const initialState = {};

export default function sso(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SSO_LOGIN:
      return reduceModelHandler(state, action);

    case SSO_LOGIN_MFA: {
      const newState = reduceModelHandler(state, action);
      return {
        data: {
          ...state.data,
          ...newState.data,
        },
      };
    }

    case SSO_MFA_RESEND_CODE:
      return state;

    default:
      return state;
  }
}
