import { createSmoothProgress } from 'utils/smoothProgress';
import { createPoll } from 'utils/poll';
import { updateCloudKey } from 'state/models/system';
import { fetchUpdateProgress, selectUpdateProgress, UPDATE_PROGRESS_STATUS } from 'state/models/updateProgress';

const EST_TIME_TO_REBOOT = 100 * 1000;

export const SET_UPDATE_PROGRESS = 'SET_UPDATE_PROGRESS';
export const setUpdateProgress = progress => ({ type: SET_UPDATE_PROGRESS, progress });

export const updateDevice = () => async (dispatch, getState) => {
  let updateFinishCounter = 0;

  const smoothProgress = createSmoothProgress({ setProgressAction: progress => dispatch(setUpdateProgress(progress)) });

  return new Promise(async (resolve, reject) => {
    try {
      smoothProgress.generateProgressBetween(1, 5);

      await dispatch(updateCloudKey());

      const poll = createPoll(async () => {
        try {
          await dispatch(fetchUpdateProgress());

          const {
            cloudkey_fw_updating,
            cloudkey_unifi_updating,
            cloudkey_fw_download_progress,
            cloudkey_fw_updating_progress,
          } = selectUpdateProgress(getState());

          const isUpdateFinished = cloudkey_fw_updating === false && cloudkey_unifi_updating === false;
          const isUpdateFinishedAndConfirmed =
            isUpdateFinished && (updateFinishCounter += 1) && updateFinishCounter > 3;
          if (isUpdateFinishedAndConfirmed) {
            poll.stop();
            smoothProgress.setProgress(100);
            resolve();
          } else {
            switch (cloudkey_fw_updating_progress) {
              case UPDATE_PROGRESS_STATUS.DOWNLOADING:
                if (!cloudkey_fw_download_progress) {
                  smoothProgress.generateProgressBetween(5, 15);
                } else {
                  smoothProgress.setProgress(10 + 0.3 * cloudkey_fw_download_progress);
                }
                break;
              case UPDATE_PROGRESS_STATUS.CHECKING:
                smoothProgress.generateProgressBetween(45, 50);
                break;
              default:
              // do nothing
            }
          }
        } catch (e) {
          // restarting
          smoothProgress.generateProgressBetween(50, 100, EST_TIME_TO_REBOOT);
        }
      }, 2000);
      poll.start();
    } catch (e) {
      smoothProgress.destroy();
      reject(e);
    }
  });
};

export const SKIP_UPDATE = 'SKIP_UPDATE';
export const skipUpdate = () => ({ type: SKIP_UPDATE });
