import { connect } from 'react-redux';

import { selectIsConsole } from 'state/models/sysInfo';
import AdvancedAccess from './AdvancedAccess';

const mapStateToProps = state => ({
  isConsole: selectIsConsole(state),
});

export default connect(
  mapStateToProps,
  null,
)(AdvancedAccess);
