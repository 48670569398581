import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { Text, Button, ButtonGroup } from '@ubnt/ui-components';
import ArrowLeftIcon from '@ubnt/icons-5/ArrowLeftIcon';
import ArrowRightIcon from '@ubnt/icons-5/ArrowRightIcon';

const Info = styled.div`
  margin-bottom: 16px;
`;

export default function Pagination({ currentPage, totalPages, totalRows, pagination }) {
  return totalPages > 1 ? (
    <div>
      <Info>
        <Text color="tertiary">
          <FormattedMessage
            id="COMMON_PAGINATION"
            values={{
              currentPage,
              totalPages,
              totalRows,
              startRow: (currentPage - 1) * pagination.rowsPerPage + 1,
              endRow: currentPage < totalPages ? currentPage * pagination.rowsPerPage : totalRows,
              rowsPerPage: Math.min(totalRows, pagination.rowsPerPage),
            }}
          />
        </Text>
      </Info>
      <ButtonGroup variant="joined">
        <Button
          type="button"
          variant="pagination"
          size="small"
          disabled={currentPage <= 1}
          onClick={() => pagination.setCurrentPage(currentPage - 1)}
        >
          <ArrowLeftIcon size="small" />
        </Button>
        <Button
          type="button"
          variant="pagination"
          size="small"
          disabled={currentPage === totalPages}
          onClick={() => pagination.setCurrentPage(currentPage + 1)}
        >
          <ArrowRightIcon size="small" />
        </Button>
      </ButtonGroup>
    </div>
  ) : null;
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pagination: PropTypes.object.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
};
