import apiClient from 'utils/api/apiClient';

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const fetchCountries = () => dispatch => {
  const promise = apiClient.get('/api/stat/ccode');

  dispatch({ type: FETCH_COUNTRIES, promise });

  return promise;
};

export const SET_COUNTRY = 'SET_COUNTRY';
export const setCountry = ({ code }) => dispatch => {
  const promise = apiClient.post('/api/set/setting/country', {
    code,
  });

  dispatch({ type: SET_COUNTRY });

  return promise;
};
