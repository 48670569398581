import { reduceCollectionHandler } from 'utils/model';
import { FETCH_TIMEZONES } from './actions';

const initialState = { data: [] };

export default function timezones(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case FETCH_TIMEZONES:
      return reduceCollectionHandler(state, action);

    default:
      return state;
  }
}
