import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Text from '@ubnt/react-text';

export default function ReviewValueBoolean({ value }) {
  return (
    <Text weight="700">
      <FormattedMessage id={value ? 'COMMON_VALUE_YES' : 'COMMON_VALUE_NO'} />
    </Text>
  );
}

ReviewValueBoolean.propTypes = {
  value: PropTypes.bool,
};

ReviewValueBoolean.defaultProps = {
  value: false,
};
