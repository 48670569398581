import { BACKUP_TYPE } from 'state/models/backups/constants';
import BackupList from '../components/BackupList';
import RestoreBackup from '../components/RestoreBackup';
import RestoreCredentials from '../components/RestoreCredentials';

const steps = [
  {
    id: 'restore-backup',
    Component: RestoreBackup,
  },
  {
    id: 'credentials',
    Component: RestoreCredentials,
  },
  {
    id: 'backups',
    Component: BackupList,
    props: {
      backupType: BACKUP_TYPE.CLOUD,
    },
  },
];

export default {
  id: 'cloudFlow',
  props: {
    flowTitleId: 'SETUP_FLOW_TITLE_RESTORE_FROM_CLOUD',
  },
  steps,
};
