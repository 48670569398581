import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Label from '@ubnt/react-label';
import Text from '@ubnt/react-text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

const StyledLabel = styled(Label)`
  font-size: 13px;
`;

export default function ToggleLabel({ labelId, descriptionId, descriptionValues, ...rest }) {
  return (
    <Wrapper>
      <StyledLabel {...rest} primary className="mb-2">
        <FormattedMessage id={labelId} />
      </StyledLabel>
      {descriptionId && (
        <Text color="text-secondary" display="block">
          <FormattedHTMLMessage id={descriptionId} values={descriptionValues} />
        </Text>
      )}
    </Wrapper>
  );
}

ToggleLabel.propTypes = {
  labelId: PropTypes.string.isRequired,
  descriptionId: PropTypes.string,
  descriptionValues: PropTypes.object,
};

ToggleLabel.defaultProps = {
  descriptionId: null,
  descriptionValues: {},
};
