import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MultiStepForm, { Step } from 'lib/MultiStepForm';

export default class Flow extends Component {
  constructor(props) {
    super(props);

    const { defaultFlow } = props;

    this.state = { flow: defaultFlow };
  }

  componentDidUpdate(prevProps) {
    const {
      defaultFlow: { id },
    } = this.props;
    const {
      defaultFlow: { id: prevId },
    } = prevProps;

    if (id !== prevId) {
      this.setFlow(id);
    }
  }

  setFlow = (id, goToStep = null) => {
    const { flows } = this.props;
    const newFlow = flows[id];
    const { history } = this.props;

    if (newFlow) {
      this.setState({
        flow: newFlow,
      });
    }

    if (goToStep) {
      history.push(goToStep);
    }
  };

  stepProgressFormatter = ({ currStep, totalSteps, stepProps: { flowTitleId } }) => {
    if (flowTitleId) {
      const flowTitle = <FormattedMessage id={flowTitleId} />;
      return <FormattedMessage id="SETUP_FLOW_PROGRESS_CUSTOM" values={{ currStep, totalSteps, flowTitle }} />;
    }
    return <FormattedMessage id="SETUP_FLOW_PROGRESS_DEFAULT" values={{ currStep, totalSteps }} />;
  };

  render() {
    const { handleSubmit, initialValues, validationSchema } = this.props;
    const { flow } = this.state;

    return (
      <MultiStepForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={flow.validationSchema || validationSchema}
        stepProgressFormatter={this.stepProgressFormatter}
      >
        {flow.steps.map(step => (
          <Step
            key={step.id}
            id={step.id}
            title={step.title}
            hidden={step.hidden}
            {...flow.props}
            {...step.props}
            render={formikProps => (
              <>
                <step.Component {...formikProps} setFlow={this.setFlow} />
              </>
            )}
          />
        ))}
      </MultiStepForm>
    );
  }
}

Flow.propTypes = {
  defaultFlow: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  flows: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
};

Flow.defaultProps = {
  handleSubmit: Function.prototype,
  initialValues: {},
  validationSchema: null,
};
