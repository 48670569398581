import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { showApiErrorToast } from 'utils/api';
import { FormGroup } from '@ubnt/react-forms';
import Button from '@ubnt/ui-components/Button/Button';
import CloudThinIcon from '@ubnt/icons-5/CloudThinIcon';
import HardDriveIcon from '@ubnt/icons-5/HardDriveIcon';
import Upload from '@ubnt/icons-5/UploadIcon';
import { Ujerc, UjercGroup } from '@ubnt/ui-components/Ujerc';

import FEATURE_FLAGS from 'constants/FEATURE_FLAGS';
import StepContent from 'components/StepContent';
import StepHeader from 'components/StepHeader';

export default function RestoreBackup({
  createNotification,
  history,
  removeNotification,
  setFlow,
  uploadBackup,
  renderControls,
}) {
  return (
    <StepContent fullWidth>
      <FormGroup>
        <StepHeader titleId="SETUP_HEADER_TITLE_RESTORE_FROM_BACKUP">
          <FormattedMessage id="SETUP_HEADER_DESC_RESTORE_FROM_BACKUP" />
          <Upload
            onChange={(e, fileRef) => {
              uploadBackup(fileRef.current.files[0]).catch(
                showApiErrorToast(createNotification, 'SETUP_ERROR_TITLE_RESTORE', removeNotification),
              );
            }}
          >
            <Button variant="link" component="span">
              <FormattedMessage id="SETUP_UPLOAD_BACKUP" />
            </Button>
          </Upload>
        </StepHeader>
        <UjercGroup style={{ justifyContent: 'flex-start' }}>
          {FEATURE_FLAGS.CLOUD_BACKUP && (
            <Ujerc
              icon={<CloudThinIcon style={{ width: '120px' }} />}
              name={<FormattedMessage id="SETUP_JOURNEY_HEADER_MY_CLOUD_BACKUPS" />}
              description={<FormattedMessage id="SETUP_JOURNEY_DESC_MY_CLOUD_BACKUPS" />}
              buttonLabel={<FormattedMessage id="SETUP_JOURNEY_ACTION_LIST_BACKUPS" />}
              onClick={() => setFlow('cloudFlow', 'credentials')}
              motif="light"
            />
          )}
          <Ujerc
            icon={<HardDriveIcon style={{ width: '120px' }} />}
            name={<FormattedMessage id="SETUP_JOURNEY_HEADER_MY_LOCAL_BACKUPS" />}
            description={<FormattedMessage id="SETUP_JOURNEY_DESC_MY_LOCAL_BACKUPS" />}
            buttonLabel={<FormattedMessage id="SETUP_JOURNEY_ACTION_LIST_BACKUPS" />}
            onClick={() => setFlow('localFlow', 'backups')}
          />
          {/* <Ujerc */}
          {/* icon={<UploadDashedIcon style={{ width: '120px' }} />} */}
          {/* name={<FormattedMessage id="SETUP_JOURNEY_HEADER_UPLOAD_BACKUP_FILE" />} */}
          {/* description={<FormattedMessage id="SETUP_JOURNEY_DESC_UPLOAD_BACKUP_FILE" />} */}
          {/* buttonLabel={<FormattedMessage id="SETUP_JOURNEY_ACTION_UPLOAD_FILE" />} */}
          {/* onClick={e => { */}
          {/* e.preventDefault(); */}
          {/* }} */}
          {/* /> */}
        </UjercGroup>
      </FormGroup>
      {renderControls({
        customChildren: (
          <Button variant="link" onClick={() => history.push('../configure')}>
            <FormattedMessage id="SETUP_ACTION_CONFIGURE_FLOW" />
          </Button>
        ),
        hideNext: true,
      })}
    </StepContent>
  );
}

RestoreBackup.propTypes = {
  createNotification: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  removeNotification: PropTypes.func.isRequired,
  setFlow: PropTypes.func.isRequired,
  uploadBackup: PropTypes.func.isRequired,
  renderControls: PropTypes.func.isRequired,
};
