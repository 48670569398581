import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectCountryOptions } from 'state/models/countries';

const mapStateToProps = state => ({
  countryOptions: selectCountryOptions(state),
});

import ControllerName from './ControllerName';

export default compose(
  connect(mapStateToProps),
  withRouter,
)(ControllerName);
