export const findHardwareInfo = ubntDeviceType => {
  switch (ubntDeviceType) {
    case 'UCKV1':
    case 'UCKV2':
    case 'UCKV3':
      return {
        name: 'Cloud Key',
      };

    case 'UCKG2':
      return {
        name: 'Cloud Key Gen2',
      };

    case 'UCKP':
      return {
        name: 'Cloud Key Gen2+',
      };

    case 'UDMB':
      return {
        name: 'Dream Machine',
      };

    default:
      return {
        name: 'Network application',
      };
  }
};

export const isConsole = hwInfo => hwInfo.name !== 'Network application';