import styled from '@emotion/styled';

import Breakpoints from 'utils/breakpoints';

export default styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  @media (min-width: ${Breakpoints.TabletMaxWidth}) {
    margin-left: 60px;
  }
`;
