import { DEFAULT_LANGUAGE } from './constants';

export const selectLanguage = state => state.locales.language;

export const selectTranslations = state => state.locales.translations;

export const selectFetchedParts = state => Object.keys(selectTranslations(state)[DEFAULT_LANGUAGE]);

export const selectIsFetched = state => (language, part) =>
  (selectTranslations(state)[language] || {})[part] !== undefined;
