export const UPDATE_PROGRESS_STATUS = {
  UNKNOWN: 'unknown',
  DOWNLOADING: 'downloading',
  CHECKING: 'checking',
  MOVING: 'moving',
  UPDATING: 'updating',
  SOURCING: 'sourcing',
  OTHERS: 'others',
  RESTARTING: 'restarting',
};
