import { compose } from 'redux';
import { connect } from 'react-redux';

import withLazyRenderer from 'hoc/withLazyRenderer';
import withToast from 'hoc/withToast';
import { configureController, resolveConfigure, selectConfigureProgress } from 'state/setup/configure';
import { resolveSetup } from 'state/setup';
import { selectIsSkipUpdate } from 'state/setup/update';
import { selectIsUnifiGo, selectIsUpdateAvailable } from 'state/models/sysInfo';
import { selectCountryOptions } from 'state/models/countries';
import Configure from './Configure';

const mapStateToProps = state => ({
  configureProgress: selectConfigureProgress(state),
  isSkipUpdate: selectIsSkipUpdate(state),
  isUnifiGo: selectIsUnifiGo(state),
  isUpdateAvailable: selectIsUpdateAvailable(state),
  countryOptions: selectCountryOptions(state),
});

const mapDispatchToProps = {
  configureController,
  resolveConfigure,
  resolveSetup,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withLazyRenderer(({ resolveSetup }) => resolveSetup()),
  withToast,
)(Configure);
