class Poll {
  constructor(task, delay = 0) {
    this.interval = null;
    this.task = task;
    this.delay = delay;
  }

  start() {
    if (this.interval) {
      this.stop();
    }
    this.interval = setInterval(this.task, this.delay);
  }

  stop() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}

export const createPoll = (task, delay) => new Poll(task, delay);
