import { fetchSysInfo } from 'state/models/sysInfo';
import { logout } from 'state/models/self';

export const resolveSetup = () => async dispatch => {
  try {
    await dispatch(logout());
    await dispatch(fetchSysInfo());
  } catch (e) {
    /* TODO show errors */
    /* eslint-disable-next-line no-console */
    console.error('Something went wrong!', e);
  }
};
