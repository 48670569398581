// TODO Extract to shared library

import React from 'react';
import PropTypes from 'prop-types';
import CommonDropdown from '@ubnt/ui-components/Dropdown/Dropdown';
import { injectIntl } from 'react-intl';
import { fieldValueValidation } from '../utils/formikUtils';

function Dropdown({ intl, field: { name, onChange, value }, options, afterChange, translate, ...rest }) {
  let dropdownOptions = options;
  if (translate) {
    dropdownOptions = options.map(option => ({
      ...option,
      label: intl.formatMessage({ id: option.label }, option.values || {}),
    }));
  }
  return (
    <CommonDropdown
      id={name}
      name={name}
      value={value}
      options={dropdownOptions}
      variant="secondary"
      onChange={(value, e) => {
        onChange(e);
        if (afterChange) afterChange();
      }}
      {...rest}
    />
  );
}

Dropdown.propTypes = {
  intl: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: fieldValueValidation((props, propName) => props[propName] !== undefined),
  }).isRequired,
  options: PropTypes.array.isRequired,
  afterChange: PropTypes.func,
  translate: PropTypes.bool,
};

Dropdown.defaultProps = {
  afterChange: null,
  translate: false,
};

export default injectIntl(React.memo(Dropdown));
