import apiClient from 'utils/api/apiClient';

export const SELF_LOGOUT = 'SELF_LOGOUT';
export const logout = () => dispatch => {
  const promise = apiClient.post('/api/logout');

  dispatch({ type: SELF_LOGOUT, promise });

  return promise;
};
