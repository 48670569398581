// TODO Extract to shared library

import React from 'react';
import { css, cx } from 'emotion';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const visibleCss = css`
  visibility: visible;
`;

const hiddenCss = css`
  visibility: hidden;
`;

export default function ValidationMessage({ children, className, status, translation, visible }) {
  return (
    <div
      className={cx(
        {
          'type-regular': true,
          'type-left': true,
          'my-2': true,
          [`color-${status}`]: true,
          [visibleCss]: visible,
          [hiddenCss]: !visible,
        },
        className,
      )}
    >
      {visible ? (
        <>
          {translation && <FormattedMessage id={translation} />}
          {children}
        </>
      ) : (
        <>&nbsp;</>
      )}
    </div>
  );
}

ValidationMessage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  status: PropTypes.string,
  translation: PropTypes.string,
  visible: PropTypes.bool,
};

ValidationMessage.defaultProps = {
  children: null,
  className: null,
  status: 'danger',
  translation: null,
  visible: false,
};
