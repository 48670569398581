import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import Text from '@ubnt/ui-components/Text/Text';

const Wrapper = styled.div`
  margin-bottom: ${props => props.margin}px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 564px;
`;

export default function StepHeader({
  titleId,
  titleValues,
  descriptionId,
  descriptionValues,
  bottomMargin,
  className,
  children,
}) {
  return (
    <Wrapper className={className} margin={bottomMargin}>
      <Text size="header-m" weight="bold" color="header">
        <FormattedMessage id={titleId} values={titleValues} />
      </Text>
      <Text color="tertiary" size="body">
        {children || (descriptionId && <FormattedMessage id={descriptionId} values={descriptionValues} />)}
      </Text>
    </Wrapper>
  );
}

StepHeader.propTypes = {
  bottomMargin: PropTypes.number,
  className: PropTypes.string,
  descriptionId: PropTypes.string,
  descriptionValues: PropTypes.object,
  children: PropTypes.node,
  titleId: PropTypes.string.isRequired,
  titleValues: PropTypes.object,
};

StepHeader.defaultProps = {
  bottomMargin: 24,
  descriptionValues: {},
  descriptionId: null,
  children: null,
  className: '',
  titleValues: undefined,
};
