import apiClient from 'utils/api/apiClient';

export const CHECK_IS_DEFAULT_SSH_CREDENTIALS = 'CHECK_IS_DEFAULT_SSH_CREDENTIALS';
export const checkIsDefaultSshCredentials = () => dispatch => {
  const promise = apiClient.post('/api/cmd/system', {
    cmd: 'check-ssh-credentials',
    username: 'ubnt',
    password: 'ubnt',
  });

  dispatch({ type: CHECK_IS_DEFAULT_SSH_CREDENTIALS, promise });

  return promise;
};

export const SET_SSH_USER_EMAIL = 'SET_SSH_USER_EMAIL';
export const setSshUserEmail = ({ email }) => dispatch => {
  const promise = apiClient.post('/api/cmd/system', {
    cmd: 'change-ssh-user-email',
    email,
  });

  dispatch({ type: SET_SSH_USER_EMAIL, promise });

  return promise;
};

export const SET_SSH_USERNAME = 'SET_SSH_USERNAME';
export const setSshUsername = ({ username }) => dispatch => {
  const promise = apiClient.post('/api/cmd/system', {
    cmd: 'change-ssh-username',
    username,
  });

  dispatch({ type: SET_SSH_USERNAME, promise });

  return promise;
};

export const SET_SSH_PASSWORD = 'SET_SSH_PASSWORD';
export const setSshPassword = ({ password }) => dispatch => {
  const promise = apiClient.post('/api/cmd/system', {
    cmd: 'change-ssh-password',
    password,
  });

  dispatch({ type: SET_SSH_PASSWORD, promise });

  return promise;
};

export const configureSshForCloudKey = ({ email, username, password, useUbiquitiAccount }) => async dispatch => {
  try {
    await dispatch(checkIsDefaultSshCredentials());
  } catch (e) {
    // CloudKey is using non-default ssh credentials.
    // Silently skipping configuration of ssh credentials.
    return;
  }

  if (useUbiquitiAccount) {
    await dispatch(setSshUserEmail({ email }));
  } else {
    await dispatch(setSshUsername({ username: (username || 'root').toLowerCase() }));
  }

  await dispatch(setSshPassword({ password }));
};

export const SET_SETUP_CONFIGURED = 'SET_SETUP_CONFIGURED';
export const setSetupConfigured = () => dispatch => {
  const promise = apiClient.post('/api/cmd/system', {
    cmd: 'set-installed',
  });

  dispatch({ type: SET_SETUP_CONFIGURED, promise });

  return promise;
};

export const UPDATE_CLOUD_KEY = 'UPDATE_CLOUD_KEY';
export const updateCloudKey = () => dispatch => {
  const promise = apiClient.post('/api/cmd/system', {
    cmd: 'update-cloudkey',
  });

  dispatch({ type: UPDATE_CLOUD_KEY, promise });

  return promise;
};
