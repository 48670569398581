import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ToastProvider } from '@ubnt/ui-components';

import Configure from './Configure';
import Restore from './Restore';
import Update from './Update';

function Setup() {
  return (
    <ToastProvider>
      <Switch>
        <Redirect exact from="/" to="/configure" />
        <Redirect exact from="/configure" to="/configure/controller-name" />
        <Redirect exact from="/restore" to="/restore/restore-backup" />
        <Redirect exact from="/update" to="/update/update-device" />
        <Route path="/configure/:step?" component={Configure} />
        <Route path="/restore/:step?" component={Restore} />
        <Route path="/update/:step?" component={Update} />
      </Switch>
    </ToastProvider>
  );
}

export default Setup;
