import lockr from 'lockr';
import memoizeOne from 'memoize-one';
import uuid from 'uuid/v4';

import { DEFAULT_LANGUAGE, LANGUAGES, LANGUAGES_MAPPING } from './constants';

const LANGUAGE_LOCAL_STORAGE_KEY = 'lang';

const flattenPartials = (acc, partial) => Object.assign(acc, partial);

export const determineLanguage = () => {
  const identityMapping = Object.keys(LANGUAGES).reduce((acc, lang) => Object.assign(acc, { [lang]: lang }), {});

  const fullLanguagesMapping = { ...LANGUAGES_MAPPING, ...identityMapping };
  const languageMappingKeys = Object.keys(fullLanguagesMapping);

  // From local storage
  const storedLanguage = lockr.get(LANGUAGE_LOCAL_STORAGE_KEY);
  if (storedLanguage && languageMappingKeys.includes(storedLanguage)) {
    return fullLanguagesMapping[storedLanguage];
  }

  // Try to match a language supported by the browser
  const browserLanguages = window.navigator.languages;
  if (browserLanguages) {
    for (let i = 0; i < browserLanguages.length; i += 1) {
      const browserLanguage = browserLanguages[i];
      if (languageMappingKeys.includes(browserLanguage)) {
        return fullLanguagesMapping[browserLanguage];
      }
    }
  }

  return DEFAULT_LANGUAGE;
};

export const updateLocalStorageLanguage = language => lockr.set(LANGUAGE_LOCAL_STORAGE_KEY, language);

export const flattenMessages = (messages, language) => {
  const defaultMessages = Object.values(messages[DEFAULT_LANGUAGE]).reduce(flattenPartials, {});

  if (language === DEFAULT_LANGUAGE) {
    return defaultMessages;
  }
  return {
    ...defaultMessages,
    ...Object.values(messages[language] || {}).reduce(flattenPartials, {}),
  };
};

// use memoization instead of hashing, because hashing an object as big as translations is costly
export const getTranslationsKey = memoizeOne(translations => uuid()); // eslint-disable-line no-unused-vars
