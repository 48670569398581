import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Setup from 'pages/Setup';

export default function App() {
  return (
    <Switch>
      <Route path="/" component={Setup} />
    </Switch>
  );
}
