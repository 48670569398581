import NetworkSetup from '../components/NetworkSetup';
import ReviewConfiguration from '../components/ReviewConfiguration';
import UnifiGoCredentials from '../components/UnifiGoCredentials';
import WifiSetup from '../components/WifiSetup';
import { generateValidationSchema } from '../constants';

const steps = [
  {
    id: 'credentials',
    Component: UnifiGoCredentials,
  },
  {
    id: 'network-setup',
    Component: NetworkSetup,
  },
  {
    id: 'wifi-setup',
    Component: WifiSetup,
  },
  {
    id: 'review-configuration',
    Component: ReviewConfiguration,
  },
];

export default {
  id: 'unifiGoFlow',
  steps,
  validationSchema: generateValidationSchema({ isUniFiGoFlow: true }),
};
