const MAX_DISPLAY_VALUE = 1000;

const getUnits = isSi => {
  if (isSi) {
    return {
      thresh: 1000,
      units: ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    };
  }
  return {
    thresh: 1024,
    units: ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  };
};

const round = value => {
  let precision = 3 - Math.trunc(value).toString().length;
  if (precision < 0) {
    precision = 0;
  }
  return parseFloat(Number(value).toFixed(precision));
};

const formatValue = (value, isSi) => {
  let localValue = value;
  const { thresh, units } = getUnits(isSi);

  const unit = units.find(() => {
    localValue /= thresh;
    return Math.abs(localValue) < MAX_DISPLAY_VALUE;
  });

  return {
    unit,
    formatted: localValue,
  };
};

const bytesFormatter = (value, isSi) => {
  if (!Number.isFinite(value)) {
    return '';
  }
  if (value < MAX_DISPLAY_VALUE) {
    return `${value} B`;
  }
  const { formatted, unit } = formatValue(value, isSi);
  return `${round(formatted)} ${unit}`;
};

export default bytesFormatter;
