import apiClient from 'utils/api/apiClient';

export const SSO_LOGIN = 'SSO_LOGIN';
export const SSO_LOGIN_MFA = 'SSO_LOGIN_MFA';
export const SSO_MFA_RESEND_CODE = 'SSO_MFA_RESEND_CODE';

export const ssoStandardLogin = ({ email, password, token }) => dispatch => {
  const promise = apiClient.post('/api/cmd/cloudaccess', {
    cmd: 'check-credentials',
    ubic_username: email,
    ubic_password: password,
    ubic_2fa_token: token || undefined,
    store_credentials: true,
  });

  if(!token) {
    dispatch({ type: SSO_LOGIN, promise });
  } else {
    dispatch({ type: SSO_LOGIN_MFA, promise });
  }

  return promise;
};

export const ssoPollLogin = () => dispatch => {
  const promise = apiClient.post('/api/cmd/cloudaccess', {
    cmd: 'check-credentials',
    poll_login: true,
    store_credentials: true,
  });

  dispatch({ type: SSO_LOGIN_MFA, promise });

  return promise;
};

export const mfaResendCode = ({ type, id }) => dispatch => {
  const promise = apiClient.post(`/v2/api/mfa/${type}/${id}/send`, {}, {
    headers: {
      'Content-Type': 'application/json',
    }
  });

  dispatch({ type: SSO_MFA_RESEND_CODE, promise });

  return promise;
};
