import { SET_CONFIGURE_PROGRESS } from './actions';

const initialState = {
  progress: null,
};

export default function configure(state = initialState, action) {
  const { type, progress } = action;
  switch (type) {
    case SET_CONFIGURE_PROGRESS:
      return { ...state, progress };

    default:
      return state;
  }
}
