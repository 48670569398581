import React, { Component } from 'react';
import Countdown from 'react-countdown';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Flex from '@ubnt/react-flex';
import { FormGroup } from '@ubnt/react-forms';
import { BasicToast } from '@ubnt/ui-components';
import Button from '@ubnt/ui-components/Button/Button';
import Text from '@ubnt/ui-components/Text/Text';

import { API_STRINGS } from 'utils/api';
import { Field, ValidationMessage } from 'lib/formik/components';
import Link from 'components/Link';
import StepHeader from 'components/StepHeader';
import ValidatedFieldStack from 'components/ValidatedFieldStack';
import { validateFields } from 'utils/formik';
import { SIDE_IMAGE_TYPE } from 'state/ui/constants';
import { CreateAccountInfoModal } from './CreateAccountInfoModal';
import MfaChangeAuthenticatorModal from './mfa/MfaChangeAuthenticatorModal';
import { MfaConstants, MfaDescription } from './mfa';
import { isResendAvailable as isMfaResendAvailable } from './mfa/mfaUtils';
import BackButton from '../../lib/MultiStepForm/components/BackButton';

const UI_ACCOUNT_ADDRESS = 'https://account.ui.com';
const CHECK_FIELDS = ['ubiquitiAccount', 'ubiquitiPassword', 'ubiquiti2faToken'];
const USERNAME_REGEXP = /^[0-9A-Za-z._-]+$/;

class UbiquitiUsernameError extends Error {}

const redirectToUIAccountRegister = () =>
  window.open(`${UI_ACCOUNT_ADDRESS}/register`, '_blank', 'noopener,noreferrer');

const StyledButtonGroup = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const CustomButtonsContainer = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: space-between;
  align-items: center;

  ${props => (props.alignRight ? `justify-content: flex-end;` : ``)}
`;

const RightButtonGroup = styled.div`
  display: flex;
  gap: 16px;
`;

export default class UbiquitiSignIn extends Component {
  state = {
    errorMsg: null,
    mfaIsEnabled: false,
    mfaRecentlyUsedAuthenticator: null,
    mfaResendTimeoutDate: Date.now(),
    mfaIsModalOpen: false,
    mfaCurrentPullId: undefined,
    submitting: false,
    createAccountModalOpen: false,
    showUsernameWarning: false,
  };

  ref2faTokenField = React.createRef();

  componentDidMount() {
    const { initialValues, setFieldValue } = this.props;
    setFieldValue('ubiquiti2faTokenRequired', initialValues.ubiquiti2faTokenRequired);

    // reset error if we re-enter this screen after e.g. timeout
    this.setState({ errorMsg: null });
  }

  componentWillUnmount() {
    const { setSideImage } = this.props;
    setSideImage('default');
  }

  isPush = authenticator => {
    return !!authenticator && authenticator.type === 'push';
  };

  isManualPush = authenticator => {
    const { values } = this.props;
    return this.isPush(authenticator) && values.ubiquiti2faTokenRequired;
  };

  isPollPush = authenticator => {
    const { values } = this.props;
    return this.isPush(authenticator) && !values.ubiquiti2faTokenRequired;
  };

  getAuthenticator = authenticatorId => {
    const { mfaAuthenticators } = this.props;
    return mfaAuthenticators.find(authenticator => authenticator.id === authenticatorId);
  };

  signIn = () => {
    const { fetchCloudAccess, initialValues, next, setFieldValue, ssoStandardLogin, values, setSideImage } = this.props;
    const { ubiquitiAccount: email, ubiquitiPassword: password, ubiquiti2faToken: token } = values;

    this.setState({ submitting: true, errorMsg: null, showUsernameWarning: false });

    ssoStandardLogin({ email, password, token })
      .then(({ data: { data: [{ username }] } }) => {
        // We can check username only when user has logged in with their email.
        // Then, if username is not valid (contains '#' or spaces), refuse to proceed
        // asking to change the username in the portal.
        if (username && !USERNAME_REGEXP.test(username)) {
          throw new UbiquitiUsernameError();
        }

        fetchCloudAccess();
      })
      .then(() => {
        this.setState({ submitting: false });
      })
      .then(() => next())
      .catch(error => {
        if (error instanceof UbiquitiUsernameError) {
          this.backToCredentials();
          this.setState({ showUsernameWarning: true });
          return;
        }

        const { msg } = error;
        const { defaultMfaId, mfaAuthenticators } = this.props;

        if (defaultMfaId && mfaAuthenticators.length > 0) {
          const { mfaRecentlyUsedAuthenticator: mfaRecentlyUsedAuthenticatorPre } = this.state;
          if (!mfaRecentlyUsedAuthenticatorPre) {
            this.setState({ mfaRecentlyUsedAuthenticator: this.getAuthenticator(defaultMfaId) });
            this.setState({ mfaResendTimeoutDate: Date.now() + MfaConstants.resendTimeoutDelay });
          }
        } else {
          this.setState({ mfaRecentlyUsedAuthenticator: null });
        }

        const { mfaRecentlyUsedAuthenticator } = this.state;
        const mfaIsEnabled = !!mfaRecentlyUsedAuthenticator;
        switch (msg) {
          case 'api.err.Ubic2faTokenRequired':
            this.setState({ mfaIsEnabled });
            setSideImage(SIDE_IMAGE_TYPE.MFA);

            if (mfaIsEnabled && this.isPush(mfaRecentlyUsedAuthenticator)) {
              this.pollSignIn();
            } else {
              this.setState({ errorMsg: API_STRINGS[msg] });
              setFieldValue('ubiquiti2faTokenRequired', true);
            }
            break;
          case 'api.err.Invalid2FAToken':
            this.setState({ errorMsg: API_STRINGS[msg] });
            break;
          case 'api.err.IncorrectUbicCreds':
            this.setState({ errorMsg: API_STRINGS[msg] });
            break;
          default:
            setFieldValue('ubiquiti2faTokenRequired', initialValues.ubiquiti2faTokenRequired);
            this.setState({ errorMsg: 'SETUP_RESPONSE_ERROR_UBIQUITI_CREDENTIALS_ERROR' });
        }
        setFieldValue('ubiquiti2faToken', initialValues.ubiquiti2faToken);
        this.setState({ submitting: false });
      });
  };

  pollSignIn = pullId => {
    if (pullId === undefined) {
      /* eslint-disable-next-line no-param-reassign */
      pullId = Date.now();
      this.setState({ mfaCurrentPullId: pullId });
    }

    const { mfaCurrentPullId, mfaRecentlyUsedAuthenticator } = this.state;
    if (pullId !== mfaCurrentPullId || !this.isPollPush(mfaRecentlyUsedAuthenticator)) {
      return;
    }

    const { fetchCloudAccess, next, ssoPollLogin } = this.props;

    ssoPollLogin()
      .then(res => {
        if (res.status === 202) {
          this.pollSignIn(pullId);
        } else {
          fetchCloudAccess();
          next(true);
        }
      })
      .catch(({ msg, data }) => {
        const dataMessage = data && data.data && data.data[0] && data.data[0].message;
        if (msg === 'api.err.UnauthorizedMfaRequest') {
          if (dataMessage === 'Session expired') {
            this.backToCredentials();
            // The order matters because backToCredentials overrides errorMsg
            this.setState({ errorMsg: 'SETUP_MFA_ERROR_SESSION_EXPIRED' });
          } else {
            this.setState({ errorMsg: 'SETUP_MFA_ERROR_LOGIN_WAS_DENIED' });
            this.setState({ mfaResendTimeoutDate: Date.now() });
          }
        }
      });
  };

  enterCodeManually = () => {
    const { setFieldValue } = this.props;
    setFieldValue('ubiquiti2faTokenRequired', true);
    setTimeout(() => this.ref2faTokenField.current && this.ref2faTokenField.current.focus(), 0);
  };

  sendPushVerification = () => {
    const { setFieldValue } = this.props;
    const { mfaRecentlyUsedAuthenticator } = this.state;
    setFieldValue('ubiquiti2faTokenRequired', false);
    this.resendMfaCode(mfaRecentlyUsedAuthenticator);
  };

  resendMfaCode = authenticator => {
    const { mfaResendCode, createNotification, removeNotification } = this.props;
    const { id, type } = authenticator;

    this.setState({ mfaCurrentPullId: undefined });
    this.setState({ errorMsg: null });

    if (isMfaResendAvailable(authenticator)) {
      mfaResendCode({ id, type })
        .then(() => {
          this.setState({ mfaResendTimeoutDate: Date.now() + MfaConstants.resendTimeoutDelay });
        })
        .then(() => {
          if (this.isPush(authenticator)) {
            this.pollSignIn();
          }
        })
        .catch(() => {
          createNotification(
            <BasicToast
              duration={3000}
              onClose={(e, id) => removeNotification(id)}
              title={<FormattedMessage id="SETUP_MFA_ERROR_TITLE_RESEND_CODE" />}
              type="danger"
              stateIndicator="danger"
            />,
          );
        });
    }
  };

  openMfaModal = () => {
    this.setState({ mfaIsModalOpen: true });
  };

  onChangeMfaAuthenticator = authenticator => {
    const { setFieldValue } = this.props;

    this.setState({ mfaRecentlyUsedAuthenticator: authenticator });
    this.setState({ mfaIsModalOpen: false });
    this.resendMfaCode(authenticator);
    if (this.isPush(authenticator)) {
      setFieldValue('ubiquiti2faTokenRequired', false);
    } else {
      setFieldValue('ubiquiti2faTokenRequired', true);
      setTimeout(() => this.ref2faTokenField.current && this.ref2faTokenField.current.focus(), 0);
    }
  };

  onCancelMfaAuthenticator = () => {
    this.setState({ mfaIsModalOpen: false });
  };

  backToCredentials = () => {
    const { initialValues, setFieldValue, setSideImage } = this.props;
    setFieldValue('ubiquiti2faTokenRequired', initialValues.ubiquiti2faTokenRequired);
    setFieldValue('ubiquiti2faToken', undefined);
    this.setState({ mfaCurrentPullId: undefined });
    this.setState({ mfaRecentlyUsedAuthenticator: undefined });
    this.setState({ submitting: false });
    this.setState({ mfaIsEnabled: false });
    this.setState({ errorMsg: null });
    setSideImage('default');
  };

  render() {
    const {
      errorMsg,
      submitting,
      mfaIsEnabled,
      mfaIsModalOpen,
      mfaRecentlyUsedAuthenticator,
      mfaResendTimeoutDate,
      createAccountModalOpen,
      showUsernameWarning,
    } = this.state;
    const { renderControls, validationSchema, values, mfaAuthenticators, setFlow } = this.props;
    const { ubiquiti2faTokenRequired } = values;
    const disabled = !validateFields(validationSchema, values, CHECK_FIELDS);

    return (
      <>
        <StepHeader titleId={mfaIsEnabled ? 'SETUP_HEADER_TITLE_MFA' : 'SETUP_HEADER_TITLE_SIGN_IN'} bottomMargin={28}>
          {mfaIsEnabled ? (
            <>
              <FormattedMessage id="SETUP_MFA_DESC" />
              &nbsp;
              <MfaDescription authenticator={mfaRecentlyUsedAuthenticator} isTokenRequired={ubiquiti2faTokenRequired} />
              {mfaAuthenticators.length >= 2 && (
                <>
                  &nbsp;
                  <FormattedMessage
                    id="SETUP_MFA_OTHER"
                    values={{
                      link: (
                        <Button variant="inline" type="button" onClick={() => this.openMfaModal()}>
                          <FormattedMessage id="SETUP_MFA_OTHER_LINK" />
                        </Button>
                      ),
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <FormattedMessage id={'SETUP_HEADER_DESC_SIGN_IN'} />
          )}
        </StepHeader>
        <FormGroup>
          {!mfaIsEnabled && (
            <ValidatedFieldStack>
              <Field
                name="ubiquitiAccount"
                label="SETUP_LABEL_UBIQUITI_ACCOUNT"
                autoFocus
                autoComplete="on"
                {...(showUsernameWarning
                  ? {
                      invalid: (
                        <FormattedMessage
                          id="COMMON_VALIDATION_MATCHES_SPACES_OR_SPECIAL_CHAR"
                          values={{ link: <Link href={UI_ACCOUNT_ADDRESS}>{UI_ACCOUNT_ADDRESS}</Link> }}
                        />
                      ),
                    }
                  : {})}
              />
              <Field
                name="ubiquitiPassword"
                type="password"
                passwordToggle
                label="SETUP_LABEL_UBIQUITI_PASSWORD"
                autoComplete="on"
              />
              <Link href={`${UI_ACCOUNT_ADDRESS}/reset-password`}>
                <Text size="body" color="inherit">
                  <FormattedMessage id="SETUP_FORGOT_PASSWORD" />
                </Text>
              </Link>
            </ValidatedFieldStack>
          )}

          {ubiquiti2faTokenRequired && (
            <Field
              name="ubiquiti2faToken"
              label="SETUP_LABEL_UBIQUITI_2FA_TOKEN"
              forwardedRef={this.ref2faTokenField}
              autoFocus
              style={{ marginBottom: '8px' }}
            />
          )}

          {errorMsg && <ValidationMessage translation={errorMsg} visible />}

          {mfaIsEnabled && (
            <>
              <StyledButtonGroup>
                {isMfaResendAvailable(mfaRecentlyUsedAuthenticator) &&
                  !this.isManualPush(mfaRecentlyUsedAuthenticator) && (
                    <Flex>
                      <Countdown
                        date={mfaResendTimeoutDate}
                        key={`mfaCountdown_${mfaResendTimeoutDate}`}
                        renderer={({ seconds, completed }) => {
                          if (completed) {
                            return (
                              <Button
                                variant="primary"
                                type="button"
                                onClick={() => this.resendMfaCode(mfaRecentlyUsedAuthenticator)}
                              >
                                <FormattedMessage
                                  id={
                                    this.isPush(mfaRecentlyUsedAuthenticator)
                                      ? 'SETUP_MFA_ACTION_RESEND_PROMPT'
                                      : 'SETUP_MFA_ACTION_RESEND_CODE'
                                  }
                                />
                              </Button>
                            );
                          }
                          return (
                            <Button variant="primary" type="button" disabled>
                              <FormattedMessage
                                id={
                                  this.isPush(mfaRecentlyUsedAuthenticator)
                                    ? 'SETUP_MFA_ACTION_RESEND_PROMPT_WITH_TIMEOUT'
                                    : 'SETUP_MFA_ACTION_RESEND_CODE_WITH_TIMEOUT'
                                }
                                values={{ timeout: seconds }}
                              />
                            </Button>
                          );
                        }}
                      />
                    </Flex>
                  )}
                {this.isPollPush(mfaRecentlyUsedAuthenticator) && (
                  <Flex>
                    <Button variant="secondary" type="button" onClick={() => this.enterCodeManually()}>
                      <FormattedMessage id="SETUP_MFA_ACTION_ENTER_CODE_MANUALLY" />
                    </Button>
                  </Flex>
                )}
                {this.isManualPush(mfaRecentlyUsedAuthenticator) && (
                  <Flex>
                    <Button variant="secondary" type="button" onClick={() => this.sendPushVerification()}>
                      <FormattedMessage id="SETUP_MFA_ACTION_SEND_PUSH_VERIFICATION" />
                    </Button>
                  </Flex>
                )}
              </StyledButtonGroup>

              {mfaIsModalOpen && (
                <MfaChangeAuthenticatorModal
                  authenticators={mfaAuthenticators}
                  authenticator={mfaRecentlyUsedAuthenticator}
                  isOpen={mfaIsModalOpen}
                  onChange={this.onChangeMfaAuthenticator}
                  onCancel={this.onCancelMfaAuthenticator}
                />
              )}
            </>
          )}
        </FormGroup>
        <CreateAccountInfoModal
          isOpen={createAccountModalOpen}
          onRequestClose={() => this.setState({ createAccountModalOpen: false })}
          onSkip={() => setFlow('advancedFlow', 'advanced-access')}
          onProceed={redirectToUIAccountRegister}
        />
        {renderControls({
          label: <FormattedMessage id="SETUP_LABEL_SIGN_IN" />,
          clickHandler: this.signIn,
          disabled: disabled || this.isPollPush(mfaRecentlyUsedAuthenticator),
          loading: submitting,
          hidePrevious: true,
          customChildren: (
            <CustomButtonsContainer alignRight={mfaIsEnabled}>
              {!mfaIsEnabled && (
                <Button
                  variant="inline"
                  onClick={() => this.setState({ createAccountModalOpen: true })}
                  type="button"
                  size="medium"
                >
                  <FormattedMessage id="SETUP_ADVANCED_SETUP" />
                </Button>
              )}
              <RightButtonGroup>
                <BackButton
                  onClick={mfaIsEnabled ? this.backToCredentials : () => setFlow('defaultFlow', 'controller-name')}
                  disabled={submitting}
                />
                <Button variant="secondary" onClick={redirectToUIAccountRegister} type="button">
                  <FormattedMessage id="SETUP_CREATE_ACCOUNT" />
                </Button>
              </RightButtonGroup>
            </CustomButtonsContainer>
          ),
        })}
      </>
    );
  }
}

UbiquitiSignIn.defaultProps = {
  defaultMfaId: undefined,
  mfaAuthenticators: [],
  username: undefined,
};

UbiquitiSignIn.propTypes = {
  defaultMfaId: PropTypes.string,
  createNotification: PropTypes.func.isRequired,
  fetchCloudAccess: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  mfaAuthenticators: PropTypes.array,
  mfaResendCode: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  ssoStandardLogin: PropTypes.func.isRequired,
  ssoPollLogin: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  renderControls: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  setFlow: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setSideImage: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  username: PropTypes.string,
};
