import { adoptDevices, fetchDevices, setDeviceAuth } from 'state/models/devices';
import { configureSshForCloudKey, setSetupConfigured } from 'state/models/system';
import { createAdmin } from 'state/models/siteMgr';
import { createWlan } from 'state/models/wlanConf';
import { createSmoothProgress } from 'utils/smoothProgress';
import { enableUbiquitiLogin } from 'state/models/settings';
import { enableCloudAccess, fetchCloudAccess } from 'state/models/cloudAccess';
import { fetchCountries, setCountry } from 'state/models/countries';
import { fetchApGroups, selectDefaultApGroupId } from 'state/models/apGroups';
import { selectIsCloudKey, selectIsUnifiGo } from 'state/models/sysInfo';
import { selectUbiquitiEmail, selectUbiquitiUsername } from 'state/models/sso';
import { setAutoBackup } from 'state/models/backups';
import { setControllerName } from 'state/models/identity';
import { fetchTimezones, setTimezone } from 'state/models/timezone';
import { updateAdmin } from 'state/models/account';

export const SET_CONFIGURE_PROGRESS = 'CONFIGURE_PROGRESS';
export const setConfigureProgress = progress => ({ type: SET_CONFIGURE_PROGRESS, progress });

export const configureController = ({
  autoBackupEnabled,
  cloudAccessEnabled,
  cloudBackupEnabled,
  controllerName,
  countryCode,
  localAdminEmail,
  localAdminPassword,
  localAdminUsername,
  selectedDevices,
  timezone,
  ubiquitiLoginEnabled,
  ubiquitiPassword,
  wifiName,
  wifiPassword,
  wifiSsidCombine,
}) => async (dispatch, getState) => {
  const isCloudKey = selectIsCloudKey(getState());
  const isUnifiGo = selectIsUnifiGo(getState());
  const useUbiquitiAccount = !isUnifiGo && ubiquitiLoginEnabled;
  const username = useUbiquitiAccount ? selectUbiquitiUsername(getState()) : localAdminUsername;
  const password = useUbiquitiAccount ? ubiquitiPassword : localAdminPassword;
  const email = useUbiquitiAccount ? selectUbiquitiEmail(getState()) : localAdminEmail;
  const apGroupId = selectDefaultApGroupId(getState());
  const macs = Object.keys(selectedDevices);

  const smoothProgress = createSmoothProgress({
    setProgressAction: progress => dispatch(setConfigureProgress(progress)),
  });

  try {
    smoothProgress.generateProgressBetween(1, 5);

    if (isUnifiGo) {
      await dispatch(updateAdmin({ username, password, email }));
    } else {
      await dispatch(createAdmin({ username, password, email }));
    }

    smoothProgress.generateProgressBetween(5, 10);

    if (isCloudKey) {
      await dispatch(configureSshForCloudKey({ username, password, email, useUbiquitiAccount }));
    }

    smoothProgress.generateProgressBetween(10, 15);

    await Promise.all([
      dispatch(setControllerName({ name: controllerName })),
      dispatch(setCountry({ code: countryCode })),
      dispatch(setTimezone({ timezone })),
    ]);

    smoothProgress.generateProgressBetween(15, 20);

    if (macs.length) {
      await dispatch(adoptDevices({ macs }));
    }

    smoothProgress.generateProgressBetween(20, 25);

    if (wifiName) {
      await dispatch(createWlan({ apGroupId, name: wifiName, password: wifiPassword, combineSsid: wifiSsidCombine }));
    }

    smoothProgress.generateProgressBetween(25, 35);

    if (!isUnifiGo) {
      await dispatch(setAutoBackup({ autoBackupEnabled, cloudBackupEnabled }));
    }

    await dispatch(setDeviceAuth({ username }));

    smoothProgress.generateProgressBetween(35, 40);

    if (!isUnifiGo && ubiquitiLoginEnabled) {
      await dispatch(enableUbiquitiLogin());
    }

    smoothProgress.generateProgressBetween(40, 95);

    if (!isUnifiGo && cloudAccessEnabled) {
      await dispatch(enableCloudAccess());
    }

    smoothProgress.generateProgressBetween(95, 100);

    await dispatch(setSetupConfigured());

    smoothProgress.setProgress(100);
  } catch (e) {
    smoothProgress.destroy();
    throw e;
  }
};

export const resolveConfigure = () => async dispatch => {
  try {
    await dispatch(fetchCloudAccess());
    await dispatch(fetchCountries());
    await dispatch(fetchDevices());
    await dispatch(fetchApGroups());
    await dispatch(fetchTimezones());
  } catch (e) {
    /* TODO show errors */
    /* eslint-disable-next-line no-console */
    console.error('Something went wrong!', e);
  }
};
