import get from 'lodash.get';
import { createSelector } from 'reselect';

export const selectTimezones = state => get(state, 'models.timezones.data');

export const selectTimezoneOptions = createSelector(
  selectTimezones,
  timezones =>
    timezones.map(timezone => ({
      label: `(UTC${timezone.offset}) ${timezone.timezone.replace(/_/g, ' ')}`,
      value: timezone.timezone,
    })),
);
