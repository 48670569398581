import { createPoll } from './poll';

export const createSmoothProgress = ({ delay = 1000, factory = 0.1, setProgressAction }) => {
  let prevParams = {
    from: null,
    to: null,
    time: 0,
  };

  let state = {
    percent: null,
    leftPercents: null,
    leftTime: null,
  };

  const updateState = (percent, leftPercents, leftTime) => {
    state = { percent, leftPercents, leftTime };
    setProgressAction(percent);
  };

  const poll = createPoll(() => {
    const { percent, leftPercents, leftTime } = state;
    const leftSeconds = leftTime ? Math.floor(leftTime / 1000) : 0;
    const isTimeBased = leftSeconds > 0;
    const increase = isTimeBased ? (leftPercents * (1 - factory)) / leftSeconds : factory * leftPercents;
    const threshold = leftPercents - increase;

    updateState(percent + increase, threshold, isTimeBased ? leftTime - delay : leftTime);
  }, delay);

  const areDifferentParams = (from, to, time) => {
    const { from: prevFrom, to: prevTo, time: prevTime } = prevParams;
    return !(from === prevFrom && to === prevTo && time === prevTime);
  };

  const generateProgressBetween = (from, to, time = 0) => {
    if (areDifferentParams(from, to, time)) {
      const threshold = to - from;
      poll.stop();
      updateState(from, threshold, time - delay);
      if (threshold > 0) {
        poll.start();
      }
    }

    prevParams = { from, to, time };
  };

  const setProgress = progress => generateProgressBetween(progress, progress);

  const destroy = () => {
    poll.stop();
    updateState(null, null);
  };

  return {
    generateProgressBetween,
    setProgress,
    destroy,
  };
};
