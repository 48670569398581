import { connect } from 'react-redux';

import { selectDeviceName } from 'state/models/sysInfo';
import RestoreCredentials from './RestoreCredentials';

const mapStateToProps = state => ({
  deviceName: selectDeviceName(state),
});

export default connect(mapStateToProps)(RestoreCredentials);
