// TODO Extract to shared library

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { isInvalid } from '../utils/formikUtils';
import TextArea from './TextArea';
import ValidationMessage from './ValidationMessage';

export default function ValidatedTextArea(props) {
  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, submitCount },
    validationMessageClassName,
    ...rest
  } = props;

  const invalid = isInvalid(name, errors, touched, submitCount);
  return (
    <>
      <TextArea
        id={name}
        name={name}
        onChange={(val, e) => onChange(e)}
        onBlur={onBlur}
        value={value !== undefined && value !== null ? value : ''}
        invalid={invalid}
        {...rest}
      />
      <ValidationMessage className={validationMessageClassName} visible={invalid}>
        {get(errors, name)}
      </ValidationMessage>
    </>
  );
}

ValidatedTextArea.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    submitCount: PropTypes.number.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  validationMessageClassName: PropTypes.string,
};

ValidatedTextArea.defaultProps = {
  validationMessageClassName: null,
};
