import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withToast from 'hoc/withToast';
import { skipUpdate, updateDevice } from 'state/setup/update';
import UpdateDevice from './UpdateDevice';

const mapDispatchToProps = {
  skipUpdate,
  updateDevice,
};

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withRouter,
  withToast,
)(UpdateDevice);
