import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { uploadBackup } from 'state/models/backups';
import withToast from 'hoc/withToast';
import RestoreBackup from './RestoreBackup';

const mapDispatchToProps = {
  uploadBackup,
};

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withRouter,
  withToast,
)(RestoreBackup);
