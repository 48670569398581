import { connect } from 'react-redux';

import { selectDeviceName } from 'state/models/sysInfo';
import Credentials from './Credentials';

const mapStateToProps = state => ({
  deviceName: selectDeviceName(state),
});

export default connect(
  mapStateToProps,
  null,
)(Credentials);
