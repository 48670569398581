import apiClient from 'utils/api/apiClient';

export const SET_CONTROLLER_NAME = 'SET_CONTROLLER_NAME';
export const setControllerName = ({ name }) => dispatch => {
  const promise = apiClient.post('/api/set/setting/super_identity', {
    name,
  });

  dispatch({ type: SET_CONTROLLER_NAME });

  return promise;
};
