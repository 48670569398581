import { compose } from 'redux';
import { connect } from 'react-redux';

import withLazyRenderer from 'hoc/withLazyRenderer';
import { fetchCloudAccess } from 'state/models/cloudAccess';
import { resolveSetup } from 'state/setup';
import { selectIsUnifiGo } from 'state/models/sysInfo';
import Restore from './Restore';

const mapStateToProps = state => ({
  isUnifiGo: selectIsUnifiGo(state),
});

const mapDispatchToProps = {
  fetchCloudAccess,
  resolveSetup,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withLazyRenderer(({ resolveSetup }) => resolveSetup()),
)(Restore);
