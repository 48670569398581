import React from 'react';
import PropTypes from 'prop-types';

import StepContent from 'components/StepContent';
import UbiquitiSignIn from 'components/UbiquitiSignIn';

export default function Credentials(props) {
  return (
    <StepContent>
      <UbiquitiSignIn {...props} />
    </StepContent>
  );
}

Credentials.propTypes = {
  deviceName: PropTypes.string.isRequired,
  next: PropTypes.func.isRequired,
  setFlow: PropTypes.func.isRequired,
  switchToAdvancedFlow: PropTypes.bool,
};

Credentials.defaultProps = {
  switchToAdvancedFlow: false,
};
