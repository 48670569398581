import React from 'react';
import PropTypes from 'prop-types';

import StepContent from 'components/StepContent';
import StepHeader from 'components/StepHeader';
import { validateFields } from 'utils/formik';
import { ReviewContainer, ReviewItem } from './components';
import { generateReviewItems } from './constants';

const CHECK_FIELDS = ['countryCode', 'timezone'];

function ReviewConfiguration({ deviceName, isUnifiGo, renderControls, validationSchema, values }) {
  const disabled = !validateFields(validationSchema, values, CHECK_FIELDS);

  return (
    <StepContent>
      <StepHeader
        titleId="SETUP_HEADER_TITLE_REVIEW_CONFIGURATION"
        descriptionId="SETUP_HEADER_DESC_REVIEW_CONFIGURATION"
        descriptionValues={{ deviceName }}
      />
      <ReviewContainer>
        {generateReviewItems(isUnifiGo)
          .filter(({ isHidden }) => !isHidden || !isHidden(values))
          .map(({ extractValue, id, labelId, renderValue }) => (
            <ReviewItem key={id} labelId={labelId} value={extractValue(values, id)} renderValue={renderValue} />
          ))}
      </ReviewContainer>
      {renderControls({ disabled })}
    </StepContent>
  );
}

ReviewConfiguration.propTypes = {
  deviceName: PropTypes.string.isRequired,
  isUnifiGo: PropTypes.bool.isRequired,
  renderControls: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default ReviewConfiguration;
