import React from 'react';
import PropTypes from 'prop-types';

import Text from '@ubnt/react-text';

export default function ReviewValueText({ value }) {
  return <Text weight="700">{value}</Text>;
}

ReviewValueText.propTypes = {
  value: PropTypes.string,
};

ReviewValueText.defaultProps = {
  value: '',
};
