import apiClient from 'utils/api/apiClient';
import { BACKUP_TYPE } from './constants';

export const CHOOSE_BACKUP = 'CHOOSE_BACKUP';
export const FETCH_BACKUPS = 'FETCH_BACKUPS';

export const fetchBackups = () => dispatch => {
  const promise = apiClient.post('/api/cmd/backup', {
    cmd: 'list-backups',
  });

  dispatch({ type: FETCH_BACKUPS, promise });

  return promise;
};

export const SET_AUTO_BACKUP = 'SET_AUTO_BACKUP';
export const setAutoBackup = ({ autoBackupEnabled, cloudBackupEnabled }) => dispatch => {
  const promise = apiClient.post('/api/set/setting/super_mgmt', {
    autobackup_enabled: autoBackupEnabled,
    backup_to_cloud_enabled: autoBackupEnabled && cloudBackupEnabled,
  });

  dispatch({ type: SET_AUTO_BACKUP });

  return promise;
};

export const UPLOAD_BACKUP = 'UPLOAD_BACKUP';
export const uploadBackup = file => dispatch => {
  const formData = new FormData();
  formData.append('file', file);
  const promise = apiClient.post('/upload/backup', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });

  dispatch({ type: UPLOAD_BACKUP, promise });

  return promise;
};

export const chooseBackup = (backupType, meta) => dispatch => {
  const cmd = backupType === BACKUP_TYPE.CLOUD ? 'choose-cloud-backup' : 'choose-backup';
  const promise = apiClient.post('/api/cmd/backup', {
    cmd,
    device_id: meta.deviceId,
    filename: meta.filename,
  });

  dispatch({ type: CHOOSE_BACKUP, promise, meta });

  return promise;
};

export const RESTORE_BACKUP = 'RESTORE_BACKUP';
export const restoreBackup = backupId => dispatch => {
  const promise = apiClient.post('/api/cmd/backup', {
    cmd: 'restore',
    backup_id: backupId,
  });

  dispatch({ type: RESTORE_BACKUP, promise });

  return promise;
};

export const DISCARD_BACKUP = 'DISCARD_BACKUP';
export const discardBackup = backupId => dispatch => {
  const promise = apiClient.post('/api/cmd/backup', {
    cmd: 'cancel-backup',
    backup_id: backupId,
  });

  dispatch({ type: DISCARD_BACKUP, promise });

  return promise;
};

export const GET_BACKEND_STATUS = 'GET_BACKEND_STATUS';
export const getBackendStatus = () => dispatch => {
  const promise = apiClient.get('/status');

  dispatch({ type: GET_BACKEND_STATUS, promise });

  return promise;
};
