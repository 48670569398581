import apiClient from 'utils/api/apiClient';

export const ENABLE_UBIQUITI_LOGIN = 'ENABLE_UBIQUITI_LOGIN';
export const enableUbiquitiLogin = () => dispatch => {
  const promise = apiClient.post('/api/set/setting/super_sdn', {
    sso_login_enabled: true,
  });

  dispatch({ type: ENABLE_UBIQUITI_LOGIN });

  return promise;
};
