import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { BasicToast } from '@ubnt/ui-components';

import Flow from 'components/Flow';
import FlowWrapper from 'components/FlowWrapper';
import PageWrapper from 'components/PageWrapper';
import Progress from 'components/Progress';
import { API_STRINGS } from 'utils/api';
import { initialValues, generateValidationSchema } from './constants';
import * as flows from './flows';

const { defaultFlow, unifiGoFlow } = flows;

export default class Configure extends Component {
  state = {};

  defaultFlow = defaultFlow;

  constructor(props) {
    super(props);

    const { isUnifiGo } = props;

    this.defaultFlow = isUnifiGo ? unifiGoFlow : defaultFlow;
  }

  componentDidMount() {
    const { isSkipUpdate, isUpdateAvailable, resolveConfigure } = this.props;

    if (isUpdateAvailable && !isSkipUpdate) {
      this.gotoUpdate();
    }

    resolveConfigure();
  }

  configureController = values => {
    const { configureController, createNotification, removeNotification } = this.props;

    configureController(values)
      .then(() => setTimeout(() => this.reloadPage(), 200))
      .catch(({ msg }) => {
        const detailsId = API_STRINGS[msg];

        createNotification(
          <BasicToast
            duration={3000}
            details={detailsId ? <FormattedHTMLMessage id={detailsId} /> : undefined}
            onClose={(e, id) => removeNotification(id)}
            title={<FormattedMessage id="SETUP_ERROR_TITLE_CONFIGURE" />}
            type="danger"
            stateIndicator="danger"
          />,
        );
      });
  };

  reloadPage = () => {
    window.location.reload(true);
  };

  gotoUpdate = () => {
    const { history } = this.props;
    history.push('../update');
  };

  render() {
    const { configureProgress, countryOptions } = this.props;
    const { sideImage } = this.state;

    if (countryOptions.length === 1) {
      initialValues.countryCode = countryOptions[0].value;
    }

    return (
      <PageWrapper sideImage={sideImage}>
        <FlowWrapper>
          {configureProgress !== null ? (
            <Progress percent={configureProgress} messageId="SETUP_SETTING_UP_TITLE" />
          ) : (
            <Flow
              defaultFlow={this.defaultFlow}
              handleSubmit={this.configureController}
              initialValues={initialValues}
              flows={flows}
              validationSchema={generateValidationSchema()}
            />
          )}
        </FlowWrapper>
      </PageWrapper>
    );
  }
}

Configure.defaultProps = {
  configureProgress: null,
};

Configure.propTypes = {
  configureController: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  configureProgress: PropTypes.number,
  history: PropTypes.object.isRequired,
  isSkipUpdate: PropTypes.bool.isRequired,
  isUnifiGo: PropTypes.bool.isRequired,
  isUpdateAvailable: PropTypes.bool.isRequired,
  removeNotification: PropTypes.func.isRequired,
  resolveConfigure: PropTypes.func.isRequired,
  countryOptions: PropTypes.array.isRequired,
};
