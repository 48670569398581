import memoizeOne from 'memoize-one';
import Yup from './yup';

export const validateFields = memoizeOne((validationSchema, values, fields) => {
  const matchingSchemas = fields.reduce((acc, field) => {
    acc[field] = Yup.reach(validationSchema, field);
    return acc;
  }, {});

  const schema = Yup.object().shape(matchingSchemas);

  try {
    schema.validateSync(values);
    return true;
  } catch (e) {
    return false;
  }
});

export const skipWifiSetup = ({ selectedDevices }) => {
  return !Object.values(selectedDevices).some(Boolean);
};
