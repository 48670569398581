import apiClient from 'utils/api/apiClient';

export const SET_TIMEZONE = 'SET_TIMEZONE';
export const setTimezone = ({ timezone }) => dispatch => {
  const promise = apiClient.post('/api/set/setting/locale', {
    timezone,
  });

  dispatch({ type: SET_TIMEZONE });

  return promise;
};

export const FETCH_TIMEZONES = 'FETCH_TIMEZONES';
export const fetchTimezones = () => dispatch => {
  const promise = apiClient.get('/v2/api/timezones');

  dispatch({ type: FETCH_TIMEZONES, promise });

  return promise;
};
