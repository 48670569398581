import apiClient from 'utils/api/apiClient';

export const ENABLE_CLOUD_ACCESS = 'ENABLE_CLOUD_ACCESS';
export const enableCloudAccess = () => dispatch => {
  const promise = apiClient.post('/api/cmd/cloudaccess', {
    cmd: 'register',
  });

  dispatch({ type: ENABLE_CLOUD_ACCESS });

  return promise;
};

export const FETCH_CLOUD_ACCESS = 'FETCH_CLOUD_ACCESS';
export const fetchCloudAccess = () => dispatch => {
  const promise = apiClient.get('/api/stat/sdn');

  dispatch({ type: FETCH_CLOUD_ACCESS, promise });

  return promise;
};
