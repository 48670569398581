import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  uploadBackup,
  chooseBackup,
  fetchBackups,
  restoreBackup,
  selectPaginatedCloudBackups,
  selectPaginatedLocalBackups,
  BACKUP_TYPE,
} from 'state/models/backups';
import withToast from 'hoc/withToast';
import withPagination from 'utils/pagination/hoc/withPagination';
import { withRouter } from 'react-router';
import BackupList from './BackupList';

const mapStateToProps = (state, { backupType, pagination }) => ({
  paginatedBackups:
    backupType === BACKUP_TYPE.CLOUD
      ? selectPaginatedCloudBackups(state)(pagination)
      : selectPaginatedLocalBackups(state)(pagination),
});

const mapDispatchToProps = {
  uploadBackup,
  chooseBackup,
  fetchBackups,
  restoreBackup,
};

export default compose(
  withPagination,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withToast,
  withRouter,
)(BackupList);
