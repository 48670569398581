// TODO Extract to shared library
import React from 'react';
import PropTypes from 'prop-types';

import CommonCheckbox from '@ubnt/ui-components/Checkbox/Checkbox';

function Checkbox({ field: { name, onChange, value }, afterChange, children, ...rest }) {
  return (
    <div style={{ display: 'flex' }}>
      <CommonCheckbox
        id={name}
        name={name}
        onChange={e => {
          onChange(e);
          if (afterChange) afterChange(e.currentTarget.value);
        }}
        checked={rest.checked || value || false}
        value={rest.value || value || false}
      >
        {children}
      </CommonCheckbox>
    </div>
  );
}

Checkbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
  }).isRequired,
  afterChange: PropTypes.func,
  children: PropTypes.node,
};

Checkbox.defaultProps = {
  afterChange: null,
  children: null,
};

export default React.memo(Checkbox);
