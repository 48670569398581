// TODO Extract to shared library

import PropTypes from 'prop-types';
import get from 'lodash.get';

export const isInvalid = (field, errors, touched, submitCount) => {
  const showErrorMsg = get(touched, field, false) || submitCount > 0;
  const hasError = get(errors, field, false);
  return !!(showErrorMsg && hasError);
};

export const isInvalidProperty = (field, property, errors, touched, submitCount) =>
  !!((get(touched, field) || submitCount > 0) && get(errors, `${field}.${property}`));

export const formikPropTypes = {
  dirty: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export const fieldValueValidation = condition => (props, propName, componentName) => {
  if (!condition(props, propName, componentName)) {
    return new Error(
      `Invalid prop "${propName}" of type ${typeof props[propName]} supplied to ${componentName} - field name: "${
        props.name
      }"`,
    );
  }
  return null;
};
