import React from 'react';
import propTypes from 'prop-types';

const Image = ({ alt, image, ...rest }) => <img {...rest} alt={alt} src={image} />;

Image.propTypes = {
  image: propTypes.string.isRequired,
  alt: propTypes.string.isRequired,
};

export default Image;
