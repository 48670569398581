import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Text from '@ubnt/react-text';

export default function ReviewValueToggle({ value }) {
  return (
    <Text weight="700">
      <FormattedMessage id={value ? 'COMMON_VALUE_ENABLED' : 'COMMON_VALUE_DISABLED'} />
    </Text>
  );
}

ReviewValueToggle.propTypes = {
  value: PropTypes.bool,
};

ReviewValueToggle.defaultProps = {
  value: false,
};
