import apiClient from 'utils/api/apiClient';

export const FETCH_AP_GROUPS = 'FETCH_AP_GROUPS';
export const fetchApGroups = () => dispatch => {
  const promise = apiClient.get('/v2/api/site/default/apgroups');

  dispatch({ type: FETCH_AP_GROUPS, promise });

  return promise;
};
