import React from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function MfaDescription(props) {
  const { authenticator, isTokenRequired } = props;
  switch (authenticator && authenticator.type) {
    case 'push':
      return isTokenRequired ? (
        <FormattedMessage id={isTokenRequired ? "SETUP_MFA_DESCRIPTION_PUSH_MANUAL" : "SETUP_MFA_DESCRIPTION_PUSH"} />
      ) : (
        <FormattedMessage id="SETUP_MFA_DESCRIPTION_PUSH" />
      );
    case 'totp':
      return <FormattedMessage id="SETUP_MFA_DESCRIPTION_TOTP" />;
    case 'sms':
      return <FormattedMessage id="SETUP_MFA_DESCRIPTION_SMS" values={{ recipient: authenticator.phone_number }} />;
    case 'email':
      return <FormattedMessage id="SETUP_MFA_DESCRIPTION_EMAIL" values={{ recipient: authenticator.email }} />;

    default:
      return null;
  }
}

MfaDescription.propTypes = {
  authenticator: PropTypes.any.isRequired,
  isTokenRequired: PropTypes.any.isRequired,
};

export default MfaDescription;
