import { combineReducers } from 'redux';

import { reducers as backups } from './backups';
import { reducers as cloudAccess } from './cloudAccess';
import { reducers as countries } from './countries';
import { reducers as devices } from './devices';
import { reducers as self } from './self';
import { reducers as sso } from './sso';
import { reducers as sysInfo } from './sysInfo';
import { reducers as updateProgress } from './updateProgress';
import { reducers as apGroups } from './apGroups';
import { reducers as timezones } from './timezone';

export default combineReducers({
  apGroups,
  backups,
  cloudAccess,
  countries,
  devices,
  self,
  sso,
  sysInfo,
  timezones,
  updateProgress,
});
