import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export default function AnimatedDots({ character, maxCount, frameDuration }) {
  const [count, setCount] = useState(0);

  const updateCount = () => {
    if (count < maxCount) {
      setCount(prevCount => prevCount + 1);
    } else {
      setCount(0);
    }
  };

  useEffect(() => {
    const timerId = setInterval(updateCount, frameDuration);
    return () => clearInterval(timerId);
  }, [count]);

  return <>{character.repeat(count)}</>;
}

AnimatedDots.defaultProps = {
  character: '.',
  maxCount: 3,
  frameDuration: 250,
};

AnimatedDots.propTypes = {
  character: PropTypes.string,
  maxCount: PropTypes.number,
  frameDuration: PropTypes.number,
};
