import styled from '@emotion/styled';

export default styled.div`
  width: 35%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  z-index: 1;
`;
