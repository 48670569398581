import React from 'react';
import PropTypes from 'prop-types';

import StepContent from 'components/StepContent';
import UbiquitiSignIn from 'components/UbiquitiSignIn';

export default function RestoreCredentials(props) {
  return (
    <StepContent>
      <UbiquitiSignIn {...props} />
    </StepContent>
  );
}

RestoreCredentials.propTypes = {
  deviceName: PropTypes.string.isRequired,
  next: PropTypes.func.isRequired,
  setFlow: PropTypes.func.isRequired,
};
