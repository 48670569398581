export const API_STRINGS = {
  'api.err.AdoptedDevicesLimitReached': 'API_ERROR_ADOPTED_DEVICES_LIMIT_REACHED',
  'api.err.AlreadyAdopted': 'API_ERROR_ALREADY_ADOPTED',
  'api.err.AlreadyJoined': 'API_ERROR_ALREADY_JOINED',
  'api.err.AutoChannelChannelsExceeded': 'API_ERROR_AUTO_CHANNELS_CHANNELS_EXCEEDED',
  'api.err.AutoChannelColoringImpossible': 'API_ERROR_AUTO_CHANNELS_TOO_COMPLEX',
  'api.err.AutoChannelComplexityExceeded': 'API_ERROR_AUTO_CHANNELS_TOO_COMPLEX',
  'api.err.AutoChannelIterationsReached': 'API_ERROR_AUTO_CHANNELS_TOO_COMPLEX',
  'api.err.BackupFailed': 'API_ERROR_BACKUP_FAILED',
  'api.err.BackupUnsupported': 'API_ERROR_BACKUP_UNSUPPORTED',
  'api.err.BetaQuotaReached': 'API_ERROR_BETA_QUOTA_REACHED',
  'api.err.BootFilenameInvalid': 'API_ERROR_BOOT_FILENAME_INVALID',
  'api.err.BootServerInvalid': 'API_ERROR_BOOT_SERVER_INVALID',
  'api.err.CannotAdopt': 'API_ERROR_CANNOT_ADOPT',
  'api.err.CannotAssignLicense': 'API_ERROR_CANNOT_ASSIGN_LICENSE',
  'api.err.CannotBeEmpty': 'API_ERROR_CANNOT_BE_EMPTY',
  'api.err.CannotCheckCredentials': 'API_ERROR_CANNOT_CHECK_CREDENTIALS',
  'api.err.CannotCreateLicense': 'API_ERROR_CANNOT_CREATE_LICENSE',
  'api.err.CannotRegisterDevice': 'API_ERROR_CANNOT_REGISTER_DEVICE',
  'api.err.CannotRevokeSelf': 'API_ERROR_CANNOT_REVOKE_SELF',
  'api.err.CannotUnassignLicense': 'API_ERROR_CANNOT_UNASSIGN_LICENSE',
  'api.err.ControllerBusy': 'API_ERROR_CONTROLLER_BUSY',
  'api.err.DestinationNetworkExisted': 'API_ERROR_DESTINATION_NETWORK_EXISTED',
  'api.err.DeviceBusy': 'API_ERROR_DEVICE_BUSY',
  'api.err.DeviceStreaming': 'API_ERROR_DEVICE_STREAMING',
  'api.err.DownlinksNotUpgraded': 'API_ERROR_DOWNLINKS_NOT_UPGRADED',
  'api.err.DuplicateAccountName': 'API_ERROR_DUPLICATE_ACCOUNT_NAME',
  'api.err.DuplicateMemberDevice': 'API_ERROR_DUPLICATE_MEMBER_DEVICE',
  'api.err.DuplicateSiteName': 'API_ERROR_DUPLICATE_SITE_NAME',
  'api.err.DynamicDNSServiceExisted': 'API_ERROR_DYNAMIC_DNS_SERVICE_EXISTED',
  'api.err.E_WirelessUplinkDisabled': 'API_ERROR_WIRELESS_UPLINK_DISABLED',
  'api.err.EmailExisted': 'API_ERROR_EMAIL_EXISTED',
  'api.err.EmailNotEligible': 'API_ERROR_EMAIL_NOT_ELIGIBLE',
  'api.err.ExtensionExists': 'API_ERROR_EXTENSION_EXISTS',
  'api.err.FeedbackFailed': 'API_ERROR_FEEDBACK_FAILED',
  'api.err.GatewayIpNotInSubnet': 'API_ERROR_GATEWAY_IP_NOT_IN_SUBNET',
  'api.err.GatewayRequiredForPdConfig': 'API_ERROR_GATEWAY_REQUIRED_FOR_PD_CONFIG',
  'api.err.IdInvalid': 'API_ERROR_ID_INVALID',
  'api.err.IdRequired': 'API_ERROR_ID_REQUIRED',
  'api.err.IncorrectIPAddress': 'API_ERROR_INCORRECT_IP_ADDRESS',
  'api.err.IncorrectIPRange': 'API_ERROR_INCORRECT_IP_RANGE',
  'api.err.IncorrectIPSubnetSpec': 'API_ERROR_INCORRECT_IP_SUBNET_SPEC',
  'api.err.IncorrectMultiportFwdPort': 'API_ERROR_INCORRECT_MULTIPORT_FWD_PORT',
  'api.err.IncorrectNetworkAddress': 'API_ERROR_INCORRECT_NETWORK_ADDRESS',
  'api.err.IncorrectNumberRange': 'API_ERROR_INCORRECT_NUMBER_RANGE',
  'api.err.IncorrectUbicCreds': 'API_ERROR_INCORRECT_UBIC_CREDS',
  'api.err.InternalServerError': 'API_ERROR_INTERNAL_SERVER_ERROR',
  'api.err.Invalid': 'API_ERROR_INVALID',
  'api.err.Invalid2FAToken': 'API_ERROR_INVALID_2FA_TOKEN',
  'api.err.InvalidAdminName': 'COMMON_VALIDATION_MATCHES_SPACES_OR_SPECIAL_CHAR',
  'api.err.InvalidAggregateRange': 'API_ERROR_INVALID_AGGREGATE_RANGE',
  'api.err.InvalidArgs': 'API_ERROR_INVALID_ARGS',
  'api.err.InvalidBackup': 'API_ERROR_INVALID_BACKUP',
  'api.err.InvalidBackupVersion': 'API_ERROR_INVALID_BACKUP_VERSION',
  'api.err.InvalidDHCPRange': 'API_ERROR_INVALID_DHCP_RANGE',
  'api.err.InvalidEmail': 'API_ERROR_INVALID_EMAIL',
  'api.err.InvalidFeedback': 'API_ERROR_INVALID_FEEDBACK',
  'api.err.InvalidFirmwareUrl': 'API_ERROR_INVALID_FIRMWARE_URL',
  'api.err.InvalidFixedIP': 'API_ERROR_INVALID_FIXED_IP',
  'api.err.InvalidKey': 'API_ERROR_INVALID_KEY',
  'api.err.InvalidLogin': 'API_ERROR_INVALID_LOGIN',
  'api.err.InvalidMedia': 'API_ERROR_INVALID_MEDIA',
  'api.err.InvalidMediaFile': 'API_ERROR_INVALID_MEDIA_FILE',
  'api.err.InvalidMemberDevice': 'API_ERROR_INVALID_MEMBER_DEVICE',
  'api.err.InvalidMirroringPort': 'API_ERROR_INVALID_MIRRORING_PORT',
  'api.err.InvalidObject': 'API_ERROR_INVALID_OBJECT',
  'api.err.InvalidPayload': 'API_ERROR_INVALID_PAYLOAD',
  'api.err.InvalidPortConf': 'API_ERROR_INVALID_PORT_CONF',
  'api.err.InvalidRadiusDisconnectPort': 'API_ERROR_INVALID_RADIUS_DISCONNECT_PORT',
  'api.err.InvalidRequest': 'API_ERROR_INVALID_REQUEST',
  'api.err.InvalidSampleAudio': 'API_ERROR_INVALID_SAMPLE_AUDIO',
  'api.err.InvalidSiteBackup': 'API_ERROR_INVALID_SITE_BACKUP',
  'api.err.InvalidSiteName': 'API_ERROR_INVALID_SITE_NAME',
  'api.err.InvalidTarget': 'API_ERROR_INVALID_TARGET',
  'api.err.InvalidTargetPort': 'API_ERROR_INVALID_TARGET_PORT',
  'api.err.InvalidValue': 'API_ERROR_INVALID_VALUE',
  'api.err.InvalidVerification': 'API_ERROR_INVALID_VERIFICATION',
  'api.err.InvalidVpnType': 'API_ERROR_INVALID_VPN_TYPE',
  'api.err.InvalidWanPdSize': 'API_ERROR_INVALID_WAN_PD_SIZE',
  'api.err.IpsecDynamicRoutingRequiresLocalIPAddress': 'API_ERROR_IPSEC_DYNAMIC_ROUTING_REQUIRES_LOCAL_IP_ADDRESS',
  'api.err.L2tpPskRequired': 'API_ERROR_L2TP_PSK_REQUIRED',
  'api.err.LocalCommandException': 'API_ERROR_LOCAL_COMMAND_EXCEPTION',
  'api.err.LocalSitePortFwdOverlapsIpsec': 'API_ERROR_LOCAL_SITE_PORT_FWD_OVERLAPS_IPSEC',
  'api.err.LoginRequired': 'API_ERROR_LOGIN_REQUIRED',
  'api.err.MacUsed': 'API_ERROR_MAC_USED',
  'api.err.MissingBroadcastGroup': 'API_ERROR_MISSING_BROADCAST_GROUP',
  'api.err.MissingDevices': 'API_ERROR_MISSING_DEVICES',
  'api.err.MissingStreamType': 'API_ERROR_MISSING_STREAM_TYPE',
  'api.err.NameExisted': 'API_ERROR_NAME_EXISTED',
  'api.err.NoDelete': 'API_ERROR_NO_DELETE',
  'api.err.NoEdit': 'API_ERROR_NO_EDIT',
  'api.err.NoLicense': 'API_ERROR_NO_LICENSE',
  'api.err.NonProfessionalInstaller': 'API_ERROR_NON_PROFESSIONAL_INSTALLER',
  'api.err.NoPermission': 'API_ERROR_NO_PERMISSION',
  'api.err.NoSecondGateway': 'API_ERROR_NO_SECOND_GATEWAY',
  'api.err.NoSecondRemoveUserVPN': 'API_ERROR_NO_SECOND_REMOTE_USER_VPN',
  'api.err.NoSiteContext': 'API_ERROR_NO_SITE_CONTEXT',
  'api.err.NoSuchCommand': 'API_ERROR_NO_SUCH_COMMAND',
  'api.err.NotCachedFirmware': 'API_ERROR_NOT_CACHED_FIRMWARE',
  'api.err.NotCloudKey': 'API_ERROR_NOT_CLOUD_KEY',
  'api.err.NotCompatible': 'API_ERROR_NOT_COMPATIBLE',
  'api.err.NotFound': 'API_ERROR_NOT_FOUND',
  'api.err.NotImplemented': 'API_ERROR_NOT_IMPLEMENTED',
  'api.err.NotSupported': 'API_ERROR_NOT_SUPPORTED',
  'api.err.NoTunnelIpAvailable': 'API_ERROR_NO_TUNNEL_IP_AVAILABLE',
  'api.err.NoUBNTSso': 'API_ERROR_NO_UBNT_SSO',
  'api.err.NtpAddressInvalid': 'API_ERROR_NTP_ADDRESS_INVALID',
  'api.err.ObjectReferredBy': 'API_ERROR_OBJECT_REFERRED_BY',
  'api.err.ObjectReferredByDevice': 'API_ERROR_OBJECT_REFERRED_BY_DEVICE',
  'api.err.ObjectReferredByWlanOverride': 'API_ERROR_OBJECT_REFERRED_BY_WLAN_OVERRIDE',
  'api.err.OpenvpnAddressReserved': 'API_ERROR_OPENVPN_ADDRESS_RESERVED',
  'api.err.OpenvpnAddressUsed': 'API_ERROR_OPENVPN_ADDRESS_USED',
  'api.err.OpenvpnPortReserved': 'API_ERROR_OPENVPN_PORT_RESERVED',
  'api.err.OpenvpnPortUsed': 'API_ERROR_OPENVPN_PORT_USED',
  'api.err.PasswordsDontMatch': 'API_ERROR_PASSWORDS_DONT_MATCH',
  'api.err.PdPrefixIdInvalid': 'API_ERROR_INVALID_PD_PREFIX_ID',
  'api.err.PdPrefixIdUsed': 'API_ERROR_INVALID_PD_PREFIX_ID_USED',
  'api.err.PdRequiresDhcpv6Wan': 'API_ERROR_PD_REQUIRES_DHCPV6_WAN',
  'api.err.PortFwdOverlapsIpsec': 'API_ERROR_PORT_FWD_OVERLAPS_IPSEC',
  'api.err.PortUnavailable': 'API_ERROR_PORT_UNAVAILABLE',
  'api.err.PptpModuleRequiresGre': 'API_ERROR_PPTP_MODULE_REQUIRES_GRE',
  'api.err.RefundError': 'API_ERROR_REFUND_ERROR',
  'api.err.RemoteApiError': 'API_ERROR_REMOTE_API_ERROR',
  'api.err.RemoteSitePortFwdOverlapsIpsec': 'API_ERROR_REMOTE_SITE_PORT_FWD_OVERLAPS_IPSEC',
  'api.err.RemoteSubnetOverlapped': 'API_ERROR_REMOTE_SUBNET_OVERLAPPED',
  'api.err.RemoteUserVpnRangeExceeded': 'API_ERROR_REMOTE_USER_VPN_RANGE_EXCEEDED',
  'api.err.RouterAdvPreferredLifetimeMustBeLessThanValidLifetime':
    'API_ERROR_ROUTER_ADV_PREFERRED_LIFETIME_MUST_BE_LESS_THAN_VALID_LIFETIME',
  'api.err.SdnEnabled': 'API_ERROR_SDN_ENABLED',
  'api.err.SdnNotReady': 'API_ERROR_SDN_NOT_READY',
  'api.err.ServerBusy': 'API_ERROR_SERVER_BUSY',
  'api.err.SiteVpnSubnetOverlapped': 'API_ERROR_SITE_VPN_SUBNET_OVERLAPPED',
  'api.err.SmtpAuthFailed': 'API_ERROR_SMTP_AUTH_FAILED',
  'api.err.SmtpNotEnabled': 'API_ERROR_SMTP_NOT_ENABLED',
  'api.err.SmtpSendFailed': 'API_ERROR_SMTP_SEND_FAILED',
  'api.err.SmtpUnknownFailed': 'API_ERROR_SMTP_UNKNOWN_FAILED',
  'api.err.SpectrumScanInProgress': 'API_ERROR_SPECTRUM_SCAN_IN_PROGRESS',
  'api.err.StreamDoesNotExist': 'API_ERROR_STREAM_DOES_NOT_EXIST',
  'api.err.StreamNotReady': 'API_ERROR_STREAM_NOT_READY',
  'api.err.SubnetOverlapped': 'API_ERROR_SUBNET_OVERLAPPED',
  'api.err.TftpServerInvalid': 'API_ERROR_TFTP_SERVER_INVALID',
  'api.err.TimeOffsetInvalid': 'API_ERROR_TIME_OFFSET_INVALID',
  'api.err.TooManyAggregate': 'API_ERROR_TOO_MANY_AGGREGATE',
  'api.err.TooManyBlockedUser': 'API_ERROR_TOO_MANY_BLOCKED_USER',
  'api.err.TooManyDHCPLeases': 'API_ERROR_TOO_MANY_DHCP_LEASES',
  'api.err.TooManyFixedIpUser': 'API_ERROR_TOO_MANY_FIXED_IP_USER',
  'api.err.TooManyMirror': 'API_ERROR_TOO_MANY_MIRROR',
  'api.err.TooManyMultiportPorts': 'API_ERROR_TOO_MANY_MULTIPORT_PORTS',
  'api.err.TooManyNetwork': 'API_ERROR_TOO_MANY_NETWORK',
  'api.err.TooManyQueries': 'API_ERROR_TOO_MANY_QUERIES',
  'api.err.TooManyStreams': 'API_ERROR_TOO_MANY_STREAMS',
  'api.err.TooManySwitchVlans': 'API_ERROR_TOO_MANY_SWITCH_VLANS',
  'api.err.TooManyVlanNetwork': 'API_ERROR_TOO_MANY_VLAN_NETWORK',
  'api.err.TooManyVpnClientNetwork': 'API_ERROR_TOO_MANY_VPN_CLIENT_NETWORK',
  'api.err.TooManyWirelessNetwork': 'API_ERROR_TOO_MANY_WIRELESS_NETWORK',
  'api.err.TooManyWirelessNetworkInWlanGroup': 'API_ERROR_TOO_MANY_WIRELESS_NETWORK_IN_WLAN_GROUP',
  'api.err.Ubic2faTokenRequired': 'API_ERROR_UBIC_2FA_TOKEN_REQUIRED',
  'api.err.UbicUnreachable': 'API_ERROR_UBIC_UNREACHABLE',
  'api.err.UBNTSsoExisted': 'API_ERROR_UBNT_SSO_EXISTED',
  'api.err.UniFiControllerInvalid': 'API_ERROR_UNIFI_CONTROLLER_INVALID',
  'api.err.UnknownDevice': 'API_ERROR_UNKNOWN_DEVICE',
  'api.err.UnknownGuest': 'API_ERROR_UNKNOWN_GUEST',
  'api.err.UnknownPayment': 'API_ERROR_UNKNOWN_PAYMENT',
  'api.err.UnknownPaymentGateway': 'API_ERROR_UNKNOWN_PAYMENT_GATEWAY',
  'api.err.UnknownStation': 'API_ERROR_UNKNOWN_STATION',
  'api.err.UnknownUpload': 'API_ERROR_UNKNOWN_UPLOAD',
  'api.err.UnsupportedDevicesOpNotAllowed': 'API_ERROR_UNSUPPORTED_DEVICES_OPERATION_NOT_ALLOWED',
  'api.err.UnknownUser': 'API_ERROR_UNKNOWN_USER',
  'api.err.UpgradeInProgress': 'API_ERROR_UPGRADE_IN_PROGRESS',
  'api.err.UplinkDeviceBusy': 'API_ERROR_UPLINK_DEVICE_BUSY',
  'api.err.VlanUsed': 'API_ERROR_VLAN_USED',
  'api.err.VoiceVlanMustBeTagged': 'API_ERROR_VOICE_VLAN_MUST_BE_TAGGED',
  'api.err.WirelessUplinkDisabled': 'API_ERROR_WIRELESS_UPLINK_DISABLED',
  'api.err.WlanConfVlanModeAlreadyEnabled': 'API_ERROR_WLAN_CONF_VLAN_MODE_ALREADY_ENABLED',
  'api.err.WpadUrlInvalid': 'API_ERROR_WPAD_URL_INVALID',
};
