import React from 'react';
import PropTypes from 'prop-types';

import Field from 'lib/formik/components/Field';

export default function ReviewValueCountry({ countryOptions, value }) {
  return <Field name="countryCode" type="select" options={countryOptions} value={value} />;
}

ReviewValueCountry.propTypes = {
  countryOptions: PropTypes.array,
  value: PropTypes.string,
};

ReviewValueCountry.defaultProps = {
  countryOptions: [],
  value: 0,
};
