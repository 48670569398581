/* eslint-disable react/prop-types */
import React from 'react';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';

Yup.addMethod(Yup.string, 'wifiPassword', function wifiPassword(message) {
  const WIFI_PASSWORD_REGEX = /^[\x20-\x7E]+$/;

  return this.test(
    'wifiPassword',
    message ||
      (({ label, value }) => {
        if (!value) return '';

        const singleCharRegExp = new RegExp(WIFI_PASSWORD_REGEX.toString().slice(2, -2), 'g');
        const notAllowedList = value.replace(singleCharRegExp, '').split('');
        const notAllowedUnique = Array.from(new Set(notAllowedList))
          .sort()
          .join(', ');

        return (
          <FormattedMessage
            id="COMMON_VALIDATION_MATCHES_NOT_ALLOWED_CHARS"
            values={{ name: <FormattedMessage id={label} />, chars: notAllowedUnique }}
          />
        );
      }),
    value => !!value && !!value.match(WIFI_PASSWORD_REGEX),
  );
});

Yup.setLocale({
  mixed: {
    required: ({ label }) => (
      <FormattedMessage id="COMMON_VALIDATION_EMPTY" values={{ name: <FormattedMessage id={label} /> }} />
    ),
  },
  string: {
    email: ({ label }) => (
      <FormattedMessage id="COMMON_VALIDATION_EMAIL" values={{ name: <FormattedMessage id={label} /> }} />
    ),
    min: ({ min, label }) => (
      <FormattedMessage id="COMMON_VALIDATION_MIN_CHARS" values={{ name: <FormattedMessage id={label} />, min }} />
    ),
    max: ({ max, label }) => (
      <FormattedMessage id="COMMON_VALIDATION_MAX_CHARS" values={{ name: <FormattedMessage id={label} />, max }} />
    ),
  },
});

export default Yup;
