import React from 'react';
import Yup from 'utils/yup';
import memoizeOne from 'memoize-one';
import { FormattedMessage } from 'react-intl';

import { skipWifiSetup } from 'utils/formik';

import FEATURE_FLAGS from 'constants/FEATURE_FLAGS';

export default memoizeOne(({ isUniFiGoFlow } = {}) => {
  const areUbiquitiCredsRequired = (cloudAccessEnabled, ubiquitiLoginEnabled) =>
    !isUniFiGoFlow && (cloudAccessEnabled || ubiquitiLoginEnabled);
  const areLocalCredsRequired = ubiquitiLoginEnabled => isUniFiGoFlow || !ubiquitiLoginEnabled;
  const isLocalEmailRequired = ubiquitiLoginEnabled => !isUniFiGoFlow && !ubiquitiLoginEnabled;
  const isSkipWiFiSetup = selectedDevices => skipWifiSetup({ selectedDevices });

  return Yup.object().shape({
    controllerName: Yup.string()
      .label('SETUP_LABEL_SERVER_NAME')
      .required(),
    tosAndEula: Yup.boolean().when([], {
      is: () => !isUniFiGoFlow,
      then: Yup.boolean()
        .equals([true])
        .required(),
    }),
    cloudAccessEnabled: Yup.boolean()
      .label('SETUP_LABEL_ENABLE_REMOTE_ACCESS')
      .required(),
    ubiquitiLoginEnabled: Yup.boolean()
      .label('SETUP_LABEL_ENABLE_UBIQUITI_LOGIN')
      .required(),
    ubiquitiAccount: Yup.string().when(['cloudAccessEnabled', 'ubiquitiLoginEnabled'], {
      is: areUbiquitiCredsRequired,
      then: Yup.string()
        .label('SETUP_LABEL_UBIQUITI_ACCOUNT')
        .email()
        .required(),
    }),
    ubiquitiPassword: Yup.string().when(['cloudAccessEnabled', 'ubiquitiLoginEnabled'], {
      is: areUbiquitiCredsRequired,
      then: Yup.string()
        .label('SETUP_LABEL_UBIQUITI_PASSWORD')
        .required(),
    }),
    ubiquiti2faTokenRequired: Yup.boolean(),
    ubiquiti2faToken: Yup.string().when('ubiquiti2faTokenRequired', {
      is: true,
      then: Yup.string()
        .label('SETUP_LABEL_UBIQUITI_2FA_TOKEN')
        .required(),
    }),
    localAdminUsername: Yup.string().when('ubiquitiLoginEnabled', {
      is: areLocalCredsRequired,
      then: Yup.string()
        .label('SETUP_LABEL_LOCAL_ADMIN_USERNAME')
        .required(),
    }),
    localAdminPassword: Yup.string().when('ubiquitiLoginEnabled', {
      is: areLocalCredsRequired,
      then: Yup.string()
        .label('SETUP_LABEL_LOCAL_ADMIN_PASSWORD')
        .required(),
    }),
    localAdminPasswordConfirm: Yup.string().when('ubiquitiLoginEnabled', {
      is: areLocalCredsRequired,
      then: Yup.string()
        .label('SETUP_LABEL_LOCAL_ADMIN_PASSWORD_CONFIRM')
        .oneOf([Yup.ref('localAdminPassword')], <FormattedMessage id="SETUP_VALIDATION_PASSWORDS_MUST_MATCH" />)
        .required(),
    }),
    localAdminEmail: Yup.string().when('ubiquitiLoginEnabled', {
      is: isLocalEmailRequired,
      then: Yup.string()
        .label('SETUP_LABEL_LOCAL_ADMIN_EMAIL')
        .email()
        .required(),
    }),
    autoBackupEnabled: Yup.boolean().when([], {
      is: () => !isUniFiGoFlow,
      then: Yup.boolean()
        .label('SETUP_LABEL_ENABLE_AUTO_BACKUP')
        .required(),
    }),
    cloudBackupEnabled:
      FEATURE_FLAGS.CLOUD_BACKUP &&
      !isUniFiGoFlow &&
      Yup.boolean().when('cloudAccessEnabled', {
        is: cloudAccessEnabled => !isUniFiGoFlow && cloudAccessEnabled,
        then: Yup.boolean()
          .label('SETUP_LABEL_ENABLE_CLOUD_BACKUP')
          .required(),
      }),
    wifiName: Yup.string().when('selectedDevices', {
      is: isSkipWiFiSetup,
      then: Yup.string()
        .label('SETUP_LABEL_WIFI_NAME')
        .max(32),
      otherwise: Yup.string()
        .label('SETUP_LABEL_WIFI_NAME')
        .max(32)
        .required(),
    }),
    wifiPassword: Yup.string().when('selectedDevices', {
      is: isSkipWiFiSetup,
      then: Yup.string()
        .label('SETUP_LABEL_WIFI_PASSWORD')
        .wifiPassword()
        .min(8)
        .max(63),
      otherwise: Yup.string()
        .label('SETUP_LABEL_WIFI_PASSWORD')
        .wifiPassword()
        .min(8)
        .max(63)
        .required(),
    }),
    wifiSsidCombine: Yup.boolean()
      .label('SETUP_LABEL_ENABLE_WIFI_COMBINE')
      .required(),
    countryCode: Yup.number()
      .label('SETUP_LABEL_COUNTRY')
      .required(),
    timezone: Yup.string()
      .label('SETUP_LABEL_TIMEZONE')
      .required(),
  });
});
