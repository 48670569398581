import get from 'lodash.get';

import { reduceModelHandler } from 'utils/model';
import { FETCH_UPDATE_PROGRESS } from './actions';

const initialState = {};

const reduceUpdateProgress = (state, action) => {
  const model = reduceModelHandler(state, action);
  if (get(model, 'data.cloudkey_fw_download_progress') !== undefined) {
    const { data } = model;
    const { cloudkey_fw_download_progress } = data;
    return {
      ...model,
      data: { ...data, cloudkey_fw_download_progress: parseInt(cloudkey_fw_download_progress, 10) || 0 },
    };
  }
  return model;
};

export default function sysInfo(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case FETCH_UPDATE_PROGRESS:
      return reduceUpdateProgress(state, action);

    default:
      return state;
  }
}
