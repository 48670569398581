import { SET_SIDE_IMAGE } from './actions';

const initialState = {};

export default function sysInfo(state = initialState, { type, image }) {
  switch (type) {
    case SET_SIDE_IMAGE:
      return { ...state, sideImage: image };

    default:
      return state;
  }
}
