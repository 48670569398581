import { SET_UPDATE_PROGRESS, SKIP_UPDATE } from './actions';

const initialState = {
  progress: null,
  skip: false,
};

export default function configure(state = initialState, action) {
  const { type, progress } = action;
  switch (type) {
    case SET_UPDATE_PROGRESS:
      return { ...state, progress };

    case SKIP_UPDATE:
      return { ...state, skip: true };

    default:
      return state;
  }
}
