import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectTimezoneOptions } from 'state/models/timezone';

import Field from 'lib/formik/components/Field';

export default function ReviewValueTimezone({ value }) {
  const timezoneOptions = useSelector(selectTimezoneOptions);
  return <Field name="timezone" type="select" options={timezoneOptions} value={value} />;
}

ReviewValueTimezone.propTypes = {
  value: PropTypes.string,
};

ReviewValueTimezone.defaultProps = {
  value: false,
};
