import { reduceCollectionV2Handler } from 'utils/model';
import { FETCH_AP_GROUPS } from './actions';

const initialState = { data: [] };

export default function apGroups(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case FETCH_AP_GROUPS:
      return reduceCollectionV2Handler(state, action);

    default:
      return state;
  }
}
