import commonTranslations from 'assets/locales/en/common.json';
import setupTranslations from 'assets/locales/en/setup.json';
import apiMessagesTranslations from 'assets/locales/en/apiMessages.json';
import { LOAD_TRANSLATIONS_SUCCESS, SET_LANGUAGE } from './actions';
import { determineLanguage, updateLocalStorageLanguage } from './utils';

const initialState = {
  language: determineLanguage(),
  translations: {
    en: {
      // Pre-cache translations
      apiMessages: apiMessagesTranslations,
      common: commonTranslations,
      setup: setupTranslations,
    },
  },
};

const mergeTranslations = (state, action) => {
  const translations = { ...state.translations };
  translations[action.language] = translations[action.language] || {};
  translations[action.language][action.part] = action.translations;
  return translations;
};

export default function locale(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      updateLocalStorageLanguage(action.language);
      return { ...state, language: action.language };

    case LOAD_TRANSLATIONS_SUCCESS:
      return { ...state, translations: mergeTranslations(state, action) };

    default:
      return state;
  }
}
