import { compose } from 'redux';
import { connect } from 'react-redux';

import withLazyRenderer from 'hoc/withLazyRenderer';
import { resolveSetup } from 'state/setup';
import { selectIsUpdateAvailable } from 'state/models/sysInfo';
import { selectIsSkipUpdate, selectUpdateProgress } from 'state/setup/update';
import Update from './Update';

const mapStateToProps = state => ({
  isSkipUpdate: selectIsSkipUpdate(state),
  isUpdateAvailable: selectIsUpdateAvailable(state),
  updateProgress: selectUpdateProgress(state),
});

const mapDispatchToProps = {
  resolveSetup,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withLazyRenderer(({ resolveSetup }) => resolveSetup()),
)(Update);
