import { BACKUP_TYPE } from 'state/models/backups/constants';
import BackupList from '../components/BackupList';

const steps = [
  {
    id: 'backups',
    Component: BackupList,
    props: {
      backupType: BACKUP_TYPE.LOCAL,
    },
  },
];

export default {
  id: 'localFlow',
  steps,
};
