import { connect } from 'react-redux';

import { selectIsUnifiGo } from 'state/models/sysInfo';
import NetworkSetup from './NetworkSetup';

const mapStateToProps = state => ({
  isUnifiGo: selectIsUnifiGo(state),
});

export default connect(mapStateToProps)(NetworkSetup);
