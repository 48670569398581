import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

/*
  To make validation messages push downward but keep this whole block vertically centered
  it must have a fixed height - that when there were no validation errors for these fields.
  Otherwise, the whole input block jerks around as you input characters.
*/
const ValidatedFieldStack = ({ children }) => {
  const fieldContainerRef = useRef();
  const [initialHeight, setInitialHeight] = useState();

  useEffect(() => {
    setInitialHeight(fieldContainerRef.current.clientHeight);
  }, []);

  return (
    <FieldContainer ref={fieldContainerRef} {...(initialHeight ? { style: { height: `${initialHeight}px` } } : {})}>
      {children}
    </FieldContainer>
  );
};

ValidatedFieldStack.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ValidatedFieldStack;
