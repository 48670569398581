export default {
  controllerName: 'UniFi Network',
  cloudAccessEnabled: true,
  ubiquitiLoginEnabled: true,
  ubiquitiAccount: '',
  ubiquitiPassword: '',
  ubiquiti2faTokenRequired: false,
  ubiquiti2faToken: undefined,
  localAdminUsername: '',
  localAdminPassword: '',
  localAdminPasswordConfirm: '',
  localAdminEmail: '',
  autoBackupEnabled: true,
  cloudBackupEnabled: true,
  wifiName: '',
  wifiPassword: '',
  wifiSsidCombine: true,
  countryCode: '840', // US countryCode code
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  tosAndEula: false,
  selectAllDevices: false,
  selectedDevices: {},
};
