import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Flow from 'components/Flow';
import FlowWrapper from 'components/FlowWrapper';
import PageWrapper from 'components/PageWrapper';
import { initialValues, validationSchema } from './constants';
import * as flows from './flows';
import RestoreBackupModals from './components/RestoreBackupModals';

export default class Restore extends Component {
  componentDidMount() {
    const { fetchCloudAccess, history, isUnifiGo } = this.props;

    if (isUnifiGo) {
      history.push('../');
      return;
    }

    fetchCloudAccess();
  }

  render() {
    const { localFlow } = flows;

    return (
      <PageWrapper>
        <RestoreBackupModals />
        <FlowWrapper visible>
          <Flow
            defaultFlow={localFlow}
            initialValues={initialValues}
            flows={flows}
            validationSchema={validationSchema}
          />
        </FlowWrapper>
      </PageWrapper>
    );
  }
}

Restore.propTypes = {
  fetchCloudAccess: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isUnifiGo: PropTypes.bool.isRequired,
};
