import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';

import Button from '@ubnt/ui-components/Button/Button';

import StepContent from 'components/StepContent';
import StepHeader from 'components/StepHeader';
import { FormattedLink } from 'components/Link';
import { Field } from 'lib/formik/components';
import { validateFields } from 'utils/formik';

const CHECK_FIELDS = ['controllerName', 'tosAndEula'];

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

export default class ControllerName extends Component {
  gotoRestore = () => {
    const { history } = this.props;
    history.push('../restore');
  };

  render() {
    const { renderControls, validationSchema, values, setFieldValue, countryOptions } = this.props;
    const disabled = !validateFields(validationSchema, values, CHECK_FIELDS);

    return (
      <StepContent>
        <StepHeader
          titleId="SETUP_HEADER_TITLE_NAME_YOUR_CONTROLLER"
          descriptionId="SETUP_HEADER_DESC_NAME_YOUR_SERVER"
          descriptionValues={{
            link: <FormattedLink messageId="SETUP_HEADER_DESC_LINK" href="https://unifi.ui.com/" />,
          }}
        />
        <FieldsContainer>
          <Field name="controllerName" label="SETUP_LABEL_SERVER_NAME" autoFocus />
          <Field
            name="countryCode"
            type="select"
            options={countryOptions}
            width={345}
            value={values.countryCode}
            label={<FormattedMessage id="SETUP_REVIEW_LABEL_COUNTRY" />}
            onChange={({ value }) => setFieldValue('countryCode', value)}
          />
        </FieldsContainer>
        <Field type="checkbox" name="tosAndEula">
          <FormattedMessage
            id="SETUP_LABEL_SERVER_TOS_AND_EULA"
            values={{
              tos: <FormattedLink messageId="SETUP_LABEL_SERVER_EULA" href="https://www.ui.com/eula/" />,
              eula: (
                <FormattedLink messageId="SETUP_LABEL_SERVER_TOS" href="https://www.ui.com/legal/termsofservice/" />
              ),
            }}
          />
        </Field>

        {renderControls({
          customChildren: (
            <Button variant="link" onClick={this.gotoRestore} type="button">
              <FormattedMessage id="SETUP_ACTION_RESTORE_FLOW" />
            </Button>
          ),
          disabled,
        })}
      </StepContent>
    );
  }
}

ControllerName.defaultProps = {
  countryOptions: [],
};

ControllerName.propTypes = {
  history: PropTypes.object.isRequired,
  renderControls: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  countryOptions: PropTypes.array,
};
