import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FlowWrapper from 'components/FlowWrapper';
import PageWrapper from 'components/PageWrapper';
import Progress from 'components/Progress';
import StandaloneStepWrapper from 'components/StandaloneStepWrapper';
import UpdateDevice from './components/UpdateDevice';

export default class Update extends Component {
  componentDidMount() {
    const { isUpdateAvailable } = this.props;

    if (!isUpdateAvailable) {
      this.gotoConfigure();
    }
  }

  gotoConfigure = () => {
    const { history } = this.props;
    history.push('../configure');
  };

  render() {
    const { updateProgress } = this.props;
    const updatingInProgress = updateProgress !== null;

    return (
      <PageWrapper>
        <FlowWrapper>
          {updatingInProgress ? (
            <>
              <StandaloneStepWrapper>
                <UpdateDevice />
              </StandaloneStepWrapper>
            </>
          ) : (
            <Progress percent={updateProgress} messageId="SETUP_TEXT_UPDATING_DEVICE" />
          )}
        </FlowWrapper>
      </PageWrapper>
    );
  }
}

Update.defaultProps = {
  updateProgress: null,
};

Update.propTypes = {
  isUpdateAvailable: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  updateProgress: PropTypes.number,
};
