import axios from 'axios';

import { DEFAULT_LANGUAGE } from './constants';
import { selectLanguage, selectFetchedParts, selectIsFetched } from './selectors';

export const LOAD_TRANSLATIONS_CACHED = 'locales/loadTranslationsCached';
export const LOAD_TRANSLATIONS_FAILED = 'locales/loadTranslationsFailed';
export const LOAD_TRANSLATIONS_SUCCESS = 'locales/loadTranslationsSuccess';
export const SET_LANGUAGE = 'locales/setLanguage';

export function loadTranslations(part, language = null) {
  return (dispatch, getState) => {
    const languageToFetch = language || selectLanguage(getState());

    if (language === null && languageToFetch !== DEFAULT_LANGUAGE) {
      return Promise.all([
        dispatch(loadTranslations(part, DEFAULT_LANGUAGE)),
        dispatch(loadTranslations(part, languageToFetch)),
      ]);
    }

    const fetched = selectIsFetched(getState())(languageToFetch, part);
    if (fetched) {
      // No need to fetch the same translation file again.
      return Promise.resolve(dispatch({ type: LOAD_TRANSLATIONS_CACHED }));
    }
    const url = `/locales/${languageToFetch}/${part}.json`;

    return axios(url, {
      credentials: 'same-origin',
    })
      .then(({ data: translations }) => {
        dispatch({
          type: LOAD_TRANSLATIONS_SUCCESS,
          language: languageToFetch,
          part,
          translations,
        });
      })
      .catch(error => {
        dispatch({
          type: LOAD_TRANSLATIONS_FAILED,
          error: true,
          payload: error,
        });
      });
  };
}

export function changeLanguage(language) {
  return (dispatch, getState) => {
    const fetchedParts = selectFetchedParts(getState());
    return Promise.all(fetchedParts.map(part => dispatch(loadTranslations(part, language)))).then(() => {
      dispatch({
        type: SET_LANGUAGE,
        language,
      });
    });
  };
}
