import React, { Component } from 'react';
import memoizeOne from 'memoize-one';

const getPaginationProps = memoizeOne((setCurrentPage, setRowsPerPage, state) => ({
  setCurrentPage,
  setRowsPerPage,
  ...state,
}));

export default WrappedComponent =>
  class extends Component {
    state = {
      currentPageIndex: 0,
      rowsPerPage: 10,
    };

    setCurrentPage = currentPage => {
      this.setState({ currentPageIndex: currentPage - 1 });
    };

    setRowsPerPage = rowsPerPage => {
      this.setState({ rowsPerPage });
    };

    render() {
      return (
        <WrappedComponent
          pagination={getPaginationProps(this.setCurrentPage, this.setRowsPerPage, this.state)}
          {...this.props}
        />
      );
    }
  };
