import { connect } from 'react-redux';

import { selectHardwareInfo } from 'state/models/sysInfo';
import { selectSideImage } from '../../state/ui';
import DeviceImage from './DeviceImage';

const mapStateToProps = state => ({
  hardwareInfo: selectHardwareInfo(state),
  image: selectSideImage(state),
});

export default connect(mapStateToProps)(DeviceImage);
